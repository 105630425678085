import Vue from "vue";
import AboutUs from "../views/AboutUs.vue";
import App from "../views/Home.vue";
import Instruction1 from "../views/Instruction1.vue";
import Instruction2 from "../views/Instruction2.vue";

import VueRouter from "vue-router";
const NotFound = { template: "<div>Page not found</div>" };

Vue.use(VueRouter);

const routes = [
  {
    path: "/instruction-1",
    name: "Instruction1",
    component: Instruction1,
  },
  {
    path: "/instruction-2",
    name: "Instruction2",
    component: Instruction2,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/",
    name: "App",
    component: App,
  },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
