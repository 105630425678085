<template>
  <div>
    <v-list-item-subtitle
      class="text--primary mb-3"
      style="white-space: pre-line"
      v-text="sourceText"
    >
    </v-list-item-subtitle>

    <v-list-item-subtitle
      color="#5f6368"
      style="white-space: pre-line"
      v-text="targetText"
    >
    </v-list-item-subtitle>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "SentencePair",
  data: () => ({}),
  props: {
    sourceText: String,
    targetText: String,
  },
};
</script>