import * as XLSX from 'xlsx';
import axios from "axios";

var progressTimer = null;
var startTime = 0;
var final_text = {
    final_text_OCR: ''
};
// var root_address = 'http://53f3-2402-800-6285-93b3-d179-73d-7096-6e48.ngrok.io';
var root_address = 'http://0.0.0.0:2836'

export function checkBodyOnLoad() {
    if (progressTimer) {
        stopOCRTimer();
    }
}

export function _dropdownMenuDomain(doc) {
    var selText = doc.querySelector('.dropdown-item').text;
    doc.querySelector('#dropdownMenu1').innerHTML = selText+' <span class="caret"></span>';
    doc.querySelector('#input-contentDomain').val = selText;
}

export function _submitOCRForm(doc) {
    var formData = new FormData(doc.querySelector('#form-pre-ocr'));
    var tmpImgFile = doc.querySelector('#input-imageFile').value;
    var tmpDomain = doc.querySelector('#input-contentDomain').val;

    if (!tmpImgFile ||tmpImgFile.length==0 || tmpImgFile=='null') {
        alert('No image is selected, please choose an image!')
    }
    if (!tmpDomain || tmpDomain=='null' || tmpDomain.length==0) {
        alert('No domain is selected, please choose a domain!')
    }
    if (tmpImgFile && tmpImgFile.length!=0 && tmpImgFile!='null' && tmpDomain && tmpDomain!='null' && tmpDomain.length!=0) {
        doc.querySelector('#divProgress').style.display = 'inline';
        startTime = 0;
        progressTimer = setInterval(runOCRTimer, 1000);
        final_text.final_text_OCR = '';
        doc.querySelector('#divResult').style.display = 'none';

        axios({
            method: "post",
            url: `${root_address}/start-ocr`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "*", "Access-Control-Allow-Methods": "*"},
        })
        .then(function (response) {

            var data = response.data;

            var chosen_domain = data.chosen_domain;
            doc.querySelector('#input-contentDomain').val = chosen_domain;

            /* 2. LOAD THE MYNOM DETECTION RESULTS */
            var result_ocrlm_mynom_path = `${root_address}/${data.result_ocrlm_mynom_path}`;

            /* set up async GET request */
            var req = new XMLHttpRequest();
            req.open('GET', result_ocrlm_mynom_path, true);
            req.setRequestHeader('X-Requested-With', 'XMLHttpRequest'); 
            req.setRequestHeader('Access-Control-Allow-Origin', '*');
            req.setRequestHeader('Access-Control-Allow-Methods','*');
            var contentType = 'Content-Type: text/xml; charset=UTF-8';
            req.responseType = 'arraybuffer';
            req.overrideMimeType(contentType);

            /* Receive Result */
            req.onload = function() {
                /* Parse mynom df to an array */
                var xlsxData = new Uint8Array(req.response);
                var workbook = XLSX.read(xlsxData, {type:'array'});
                var mainSheetName = workbook.SheetNames[0];
                var mainSheet = workbook.Sheets[mainSheetName];
                var mynom_array = XLSX.utils.sheet_to_row_object_array(mainSheet);
                                                    
                /* Dynamically generate result list and areas and retrieve final text */


                for (var i=0; i<mynom_array.length; ++i) {
                    var row = mynom_array[i];
                    getOCRText(row, final_text);
                }

                /* final_text_of_OCR*/
                doc.querySelector('#final-text-OCR-Nom').innerHTML = final_text.final_text_OCR;
            }
            /* Send the request to get the mynom file */
            req.send();

            /* Show the result section */
            doneOCRTimer();
            stopOCRTimer();
            doc.querySelector('#divResult').style.display = 'inline';
        })
        .catch(function (error) {
            console.log(error.response);
        });
    }   
}

function getOCRText(row, final_text) {    
    var ucode1 = row['UNICODE1'];
    var char1 = row['CHAR1'];
    if (ucode1.length != 6) {
        char1 = `&#x${ucode1};`;
    }
    /* final_text_of_OCR*/
    var sep = ' ';
    if (row['PUNC'] == '\n') sep = '<br>';
    final_text.final_text_OCR += sep + char1;
}

function runOCRTimer() {
    startTime += 1;
    document.getElementById('progressTimer').innerHTML = startTime+'s';
}

function doneOCRTimer() {
    document.getElementById('progressTimer').innerHTML = `<b font-size:20px;>${startTime}s, DONE!</b>`;
}

function stopOCRTimer() {
    if (progressTimer) {
        startTime = 0;
        clearInterval(progressTimer);
    }
}

export function _closeDialog(context) {
    context.$refs['OCRDialog'].querySelector('#divProgress').style.display = 'none';
    context.$refs['OCRDialog'].querySelector('#divResult').style.display = 'none';
    context.messageAlert = "";
    context.$emit("update:ocrDialog", false);
}

export function _copyOCRTextToClipboardAndClose(context) {
    _closeDialog(context);
    navigator.clipboard.writeText(context.$refs['OCRDialog'].querySelector('#final-text-OCR-Nom').innerHTML.replaceAll('<br>','\n'));
}