<template>
  <v-dialog
    v-model="isOpen"
    width="auto "
    class="m-0 v-dialog"
    @click:outside="closeDialog"
  >
    <v-card class="card">
      <div class="image-cropper-container">
        <div>
          <h4>{{ $t("verticalOCRStep.step1") }}</h4>
        </div>
        <input
          ref="input"
          type="file"
          name="image"
          @change="setImage"
          accept="image/*"
        />

        <v-btn
          outlined
          color="blue darken-2"
          @click.prevent="showFileChooser"
          class="mb-3 mt-2"
        >
          {{ $t("verticalOCRStep.upload") }}
        </v-btn>
        <div v-if="step >= 2">
          <h4>
            {{ $t("verticalOCRStep.step2") }}
          </h4>
          <div class="img-cropper">
            <div class="crop-preview">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="null"
                :src="imgSrc"
                :auto-crop-area="0.6"
                preview=".preview"
              />

              <div class="preview mobile-hide" />
            </div>
            <v-btn
              outlined
              color="blue darken-2"
              class="mt-2 mb-3"
              @click.prevent="cropImage"
            >
              {{ $t("verticalOCRStep.crop") }}
            </v-btn>

            <div v-if="step >= 3 || cropImg.length > 0">
              <p>{{ $t("verticalOCRStep.croppedImages") }}</p>
              <div class="cropped-image">
                <!-- <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
          <div v-else class="crop-placeholder" /> -->
                <div v-if="index === 1 && cropImg.length === 1">
                  <img
                    v-if="cropImg"
                    :src="cropImg[0]"
                    alt="Cropped Image"
                    class="croped-imgs"
                  />
                  <div v-else class="crop-placeholder"></div>
                </div>
                <div v-for="i in index - 1" :key="i">
                  <img
                    v-if="cropImg"
                    :src="cropImg[i - 1]"
                    alt="Cropped Image"
                    class="croped-imgs"
                  />
                  <div v-else class="crop-placeholder" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="step >= 3">
          <h4>{{ $t("verticalOCRStep.step3") }}</h4>
          <v-btn depressed dark color="#1976d2" class="mt-2" @click="sendOCR">{{
            $t("verticalOCRStep.submit")
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  name: "PreviewImageDialog",
  components: {
    VueCropper,
  },
  props: ["src", "isOpen"],
  data() {
    return {
      step: 1,
      imgSrc: null,
      cropImg: [],
      index: 1,
      data: null,
    };
  },
  methods: {
    closeDialog() {
      this.clearOCR();
      this.$emit("togglePreviewDialog", false);
    },
    callOCR(base64Images) {
      this.$emit("callOCR", base64Images);
    },
    clearOCR() {
      this.step = 1;
      this.imgSrc = null;
      this.index = 1;
      this.cropImg = [];
      this.data = null;
    },

    updateStep(newStep) {
      this.step = newStep;
    },
    sendOCR() {
      this.$emit("callOCR", this.cropImg);
      this.clearOCR();
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg[this.index - 1] = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");

      this.index = this.index + 1;
      this.updateStep(3);
    },

    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // this.cropImg[0] = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
        this.updateStep(2);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.image-cropper-container {
  padding: 50px;
  width: 600px;
  /* height: 80vh;
  width: 100%; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.crop-preview {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.croped-imgs {
  max-height: 200px;
  width: auto;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: auto;
  max-width: 300px;
  max-height: 300px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  max-width: 300px;
  max-height: 200px;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
  border: 1px solid gray;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
  border: 1px solid gray;
}
.cropped-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .image-cropper-container {
    padding: 16px;
    width: auto;
  }
  .v-dialog {
    margin: 0 !important;
  }
}
@media (max-width: 486px) {
  .mobile-hide {
    display: none;
  }
  .crop-preview {
    width: 80%;
  }
}
</style>
