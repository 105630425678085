import user from "./user";
import translation from "./translation";

const apiUrlManager = {
  user,
  translation,
};

export default {
  install(Vue) {
    Vue.prototype.$api = apiUrlManager;
  },
};
