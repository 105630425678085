<template>
  <v-dialog @click:outside="closeDialog" :value="dialog" max-width="450px">
    <v-card
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
    >
      <v-alert v-if="messageAlert.length > 0" dense text type="info">
        {{ messageAlert }}
      </v-alert>
      <v-card-text>
        <!-- New Addition -->
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
            mdi-cloud-upload
          </v-icon>
          <div>Drop your file(s) here</div>
          <v-file-input
            class="center-input"
            v-model="chosenFile"
            hide-details
            hide-input
            :multiple="multiple"
          ></v-file-input>
          <p><span>OR</span> click here to select them.</p>
        </v-row>
        <v-virtual-scroll
          v-if="uploadedFiles.length > 0"
          :items="uploadedFiles"
          max-height="150"
          item-height="50"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                  <span class="ml-3 text--secondary">
                    {{ item.size }} byte(s)</span
                  >
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click.stop="removeFile(item.name)" icon>
                  <v-icon> mdi-close-circle </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closeDialog" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>

        <v-btn icon @click.stop="submit">
          <v-icon id="upload-button">mdi-upload</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.center-input {
  margin-left: 9px;
}
</style>

<script>
export default {
  name: "UploadFile",
  data: () => ({
    chosenFile: [],
    dragover: false,
    uploadedFiles: [],
    messageAlert: "",
  }),
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    onDrop(e) {
      this.dragover = false;
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        console.log("Only one file can be uploaded at a time..");
      } else {
        e.dataTransfer.files.forEach((element) =>
          this.uploadedFiles.push(element)
        );
      }
    },
    submit() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFiles.length > 0) {
        this.messageAlert = "There are no files to upload";
      } else {
        let exceed = false;

        for (let index = 0; index < this.uploadedFiles.length; index++) {
          if (this.uploadedFiles[index].size >= 5242880) {
            exceed = true;
            break;
          }
        }
        if (!exceed) {
          // Send uploaded files to parent component)
          this.$emit("filesUploaded", this.uploadedFiles);
          // Close the dialog box
          this.closeDialog();
        } else {
          this.messageAlert = "File is over 5MB.";
        }
      }
    },
  },
  watch: {
    chosenFile() {
      if (this.chosenFile != null) {
        this.chosenFile.forEach((element) => this.uploadedFiles.push(element));
      }
    },
  },
};
</script>

<!-- https://george-hadjigeorgiou97.medium.com/step-by-step-custom-drag-drop-upload-component-in-vuetify-vue-2-43c99794643d 
      https://serversideup.net/uploading-files-vuejs-axios/
      https://bezkoder.com/vue-multiple-files-upload/ -->
