var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"stack_container"},[_vm._v(" "+_vm._s(_vm.loadingText)+" "),_c('v-textarea',{ref:"highlightText",staticClass:"custom-style",attrs:{"id":this.$props.translationDirection === 'nom-qn' ||
          this.$props.translationDirection === 'han-qn'
            ? 'nom-input-area'
            : '',"color":"info","auto-grow":""},on:{"keyup":function($event){return _vm.charCount()}},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}}),_c('div',{staticClass:"custom-style-highlight",attrs:{"aria-hidden":"true","id":this.$props.translationDirection === 'nom-qn' ||
          this.$props.translationDirection === 'han-qn'
            ? 'nom-highlight-text'
            : ''},domProps:{"innerHTML":_vm._s(_vm.highlightText)}})],1),_c('v-btn',{staticClass:"setPosition",attrs:{"icon":""}},[(_vm.hidden)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":_vm.clearText}},on),[_vm._v(_vm._s(_vm.icon.clear))])]}}],null,false,3446924178)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.clear")))])])],1):_vm._e()])],1),_c('div',{staticClass:"widget-wrapper"},[_c('Counter',{attrs:{"isNom":false,"counterChar":_vm.totalcharacter},on:{"clickIcon":function($event){return _vm.$emit('clickIcon')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }