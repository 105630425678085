<template>
  <div class="target-area-container">
    <div
      class="wrapper readonlyBackground"
      v-bind:class="
        this.$props.translationDirection === 'nom-qn' ||
        this.$props.translationDirection === 'han-qn'
          ? ''
          : 'nom-font'
      "
    >
      <div id="copiedText" class="custom-style-output">
        <span
          v-for="(item, i) in viShow"
          :key="i"
          class="span-hover"
          @click="menuDropdown(i)"
          @mouseover="posId(i)"
          @mouseleave="posId(-1)"
        >
          <span v-text="item.pair.modern_text"></span>
          <br />
          <div v-if="i === isShowOtherTranslation" class="menu-dropdown">
            <div
              v-for="(subitem, subi) in viTransOutput[i]"
              :key="subi"
              class="item-dropdown"
              :class="[
                subi === viTransOutput[i].length - 1
                  ? 'item-dropdown-no-border-bottom'
                  : '',
              ]"
              @click="substitute(i, subi)"
            >
              <div
                class="subitem-tick"
                v-if="
                  subitem.pair.modern_text.trim() ==
                    item.pair.modern_text.trim() &&
                  subitem.pair.nom_text.trim() == item.pair.nom_text.trim()
                "
              >
                <svg width="18" height="18" viewBox="0 0 24 24">
                  <path
                    d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                  ></path>
                </svg>
              </div>
              <div class="subitem-tick" v-else></div>

              <div class="subitem-content">
                <div
                  class="subitem-trg"
                  v-text="subitem.pair.modern_text"
                ></div>
                <div class="subitem-src" v-text="subitem.pair.nom_text"></div>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div
        class="custom-style-interpretations"
        v-if="this.$props.interpretations.length > 0 && viShow.length > 0"
      >
        <p>{{ $t("interpretation.interpretation") }}:</p>
        <span
          v-for="(item, i) in this.$props.interpretations"
          :key="i"
          class="span-hover"
        >
          <span v-text="item"></span>
          <br />
          <div v-if="i === isShowOtherTranslation" class="menu-dropdown">
            <div
              v-for="(subitem, subi) in viTransOutput[i]"
              :key="subi"
              class="item-dropdown"
              :class="[
                subi === viTransOutput[i].length - 1
                  ? 'item-dropdown-no-border-bottom'
                  : '',
              ]"
              @click="substitute(i, subi)"
            >
              <div
                class="subitem-tick"
                v-if="
                  subitem.pair.modern_text.trim() ==
                    item.pair.modern_text.trim() &&
                  subitem.pair.nom_text.trim() == item.pair.nom_text.trim()
                "
              >
                <svg width="18" height="18" viewBox="0 0 24 24">
                  <path
                    d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                  ></path>
                </svg>
              </div>
              <div class="subitem-tick" v-else></div>

              <div class="subitem-content">
                <div
                  class="subitem-trg"
                  v-text="subitem.pair.modern_text"
                ></div>
                <div class="subitem-src" v-text="subitem.pair.nom_text"></div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <v-btn icon class="setPosition">
        <div v-show="starGone">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" v-if="!favoriteCheck" @click="favorite">{{
                icon.starOutline
              }}</v-icon>
              <v-icon
                v-on="on"
                v-else
                color="yellow darken-2"
                @click="unfavorite"
                >{{ icon.star }}</v-icon
              >
            </template>
            <span>{{
              favoriteCheck ? $t("tooltip.unsave") : $t("tooltip.save")
            }}</span>
          </v-tooltip>

          <!-- <v-icon v-on="on" v-if="!favoriteCheck" @click="favorite">{{
            icon.starOutline
          }}</v-icon> 
          <v-icon v-else color="yellow darken-2" @click="unfavorite">{{
            icon.star
          }}</v-icon>  -->
        </div>
      </v-btn>
    </div>

    <div class="widget-wrapper readonlyBackground">
      <Actions
        :widgetGone="starGone"
        @copyCmd="copyCmd"
        @editCmd="editCmd"
        @reviewCmd="reviewCmd"
        @txtCmd="txtCmd"
        @pdfCmd="pdfCmd"
        @exceptionPopup="exceptionPopup"
      />
    </div>
  </div>
</template>

<style>
.target-area-container {
  height: 100%;
}
.wrapper {
  position: relative;
  padding: 20px 56px 20px 24px;
  height: calc(100% - 56px);
}

.widget-wrapper {
  margin-top: auto;
  padding: 0 12px 8px 11px;
  height: 56px;
}

.custom-style-interpretations {
  width: 100%;
  min-height: 56px;
  border: none;
  font-size: 14px;
  -moz-box-flex: 1;
  flex: 1;
  line-height: 20px;
  background: transparent;
  color: #5f6368;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
  z-index: 1;
  display: inline-block;
}

.custom-style-output {
  width: 100%;
  min-height: 56px;
  border: none;
  font-size: 18px;
  -moz-box-flex: 1;
  flex: 1;
  line-height: 28px;
  background: transparent;
  color: #3c4043;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
  z-index: 1;
  display: inline-block;
}

.setPosition {
  position: absolute;
  top: 0;
  right: 5;
  margin-top: 17px;
}

.readonlyBackground {
  background-color: #f5f5f5 !important;
}

/* .span-hover {
  display: block;
} */

.span-hover:hover {
  cursor: pointer;
  background: #d2e3fc;
}

.menu-dropdown {
  display: block;
  position: absolute;
  max-width: 592px;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  font-size: 12px;
  padding: 8px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.22);
  z-index: 1;
}

.item-dropdown {
  border-bottom: 1px solid #dadce0;
  box-sizing: content-box;
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  min-height: 48px;
  min-width: 12px;
  padding: 0 16px;
}

.item-dropdown-no-border-bottom {
  border-bottom: 0;
}

.subitem-tick {
  color: #185abc;
  align-self: center;
  padding-right: 12px;
  width: 18px;
}

.subitem-content {
  padding: 6px 0;
}

.subitem-trg {
  color: #202124;
  font-size: 14px;
  line-height: 18px;
}

.subitem-src {
  color: #5f6368;
  font-size: 12px;
  line-height: 16px;
  padding-top: 6px;
}
</style>

<script>
import Actions from "@src/components/textarea/widget/Actions.vue";

import jsPDF from "jspdf";
import "@src/config/Roboto-Regular-normal";

export default {
  name: "CustomTextArea",
  data: () => ({
    inputText: "",
    isShowOtherTranslation: -1,
    favoriteCheck: false,
    icon: {
      star: "mdi-star",
      starOutline: "mdi-star-outline",
    },
  }),
  props: {
    starGone: Boolean,
    viTransOutput: Array,
    viShow: Array,
    translationDirection: String,
    interpretations: Array,
  },
  components: {
    Actions,
  },
  methods: {
    menuDropdown(val) {
      this.isShowOtherTranslation = val;
    },
    substitute(i, subi) {
      this.$emit("substitutePosition", { i: i, subi: subi });
      this.isShowOtherTranslation = -1;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isShowOtherTranslation = -1;
      }
    },
    posId(val) {
      if (val != -1) {
        this.$emit("highlightPos", val);
      } else {
        this.$emit("highlightPos", -1);
      }
    },
    copyCmd() {
      var range = document.createRange();
      range.selectNode(document.getElementById("copiedText"));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect
    },
    editCmd() {
      let source = "";
      let target = "";
      for (let index = 0; index < this.viShow.length; index++) {
        source += this.viShow[index].pair.nom_text;
        target += this.viShow[index].pair.modern_text;

        if (index < this.viShow.length - 1) {
          source += "\n";
          target += "\n";
        }
      }

      this.$emit("editOutput", {
        sourceText: source,
        targetText: target,
      });
    },
    reviewCmd() {
      let source = "";
      let target = "";
      for (let index = 0; index < this.viShow.length; index++) {
        source += this.viShow[index].pair.nom_text;
        target += this.viShow[index].pair.modern_text;

        if (index < this.viShow.length - 1) {
          source += "\n";
          target += "\n";
        }
      }

      this.$emit("reviewOutput", {
        sourceText: source,
        targetText: target,
      });
    },
    txtCmd() {
      this.downloadInnerHtml("vie.txt", "copiedText");
    },
    downloadInnerHtml(filename, elId, mimeType) {
      var elHtml = document.getElementById(elId).innerText;
      var link = document.createElement("a");
      mimeType = mimeType || "text/plain";

      link.setAttribute("download", filename);
      link.setAttribute(
        "href",
        "data:" + mimeType + ";charset=utf-8," + encodeURIComponent(elHtml)
      );
      link.click();
    },
    pdfCmd() {
      var doc = new jsPDF();
      var splitTitle = doc.splitTextToSize(
        document.getElementById("copiedText").innerText,
        270
      );
      // var pageHeight = doc.internal.pageSize.height;
      doc.setFont("Roboto-Regular");
      doc.setFontSize("11");
      var y = 7;
      for (var i = 0; i < splitTitle.length; i++) {
        if (y > 280) {
          y = 10;
          doc.addPage();
        }
        doc.text(15, y, splitTitle[i]);
        y = y + 7;
      }
      doc.save("vie.pdf");
    },
    async favorite() {
      try {
        let source = "";
        let target = "";
        for (let index = 0; index < this.viShow.length; index++) {
          source += this.viShow[index].pair.nom_text;
          target += this.viShow[index].pair.modern_text;

          if (index < this.viShow.length - 1) {
            source += "\n";
            target += "\n";
          }
        }
        if (this.$gAuth.isAuthorized) {
          let res = await this.$api.translation.submitFavorite({
            nom: source,
            vie: target,
          });

          if (res.status === 200 && res.data["success"]) {
            this.favoriteCheck = true;
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
            this.favoriteCheck = false;
          }
        } else {
          let favorite = localStorage?.favorite;
          // get old history from local storage and append new history
          if (favorite) {
            let previousFavorite = JSON.parse(favorite);
            if (Array.isArray(previousFavorite)) {
              let newFavorite = [
                ...previousFavorite,
                {
                  nom: source,
                  vie: target,
                },
              ];
              // save the 100 oldest favorite (because local storage only have 5MB)
              if (newFavorite.length > 100) {
                newFavorite = newFavorite.slice(newFavorite.length - 100);
              }
              localStorage.favorite = JSON.stringify(newFavorite);
            }
          }
          // create new history in local storage
          else {
            let newFavorite = JSON.stringify([
              {
                nom: source,
                vie: target,
              },
            ]);
            localStorage.favorite = newFavorite;
          }
          this.favoriteCheck = true;
        }
      } catch (error) {
        window.console.error(error);
      }
    },
    async unfavorite() {
      try {
        let source = "";
        let target = "";
        for (let index = 0; index < this.viShow.length; index++) {
          source += this.viShow[index].pair.nom_text;
          target += this.viShow[index].pair.modern_text;

          if (index < this.viShow.length - 1) {
            source += "\n";
            target += "\n";
          }
        }
        if (this.$gAuth.isAuthorized) {
          let res = await this.$api.translation.deleteFavorite({
            nom: source,
            vie: target,
          });

          if (res.status === 200 && res.data["success"]) {
            this.favoriteCheck = false;
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
            this.favoriteCheck = true;
          }
        } else {
          let favorite = localStorage.favorite;
          let arrFavorite = JSON.parse(favorite);
          let index = arrFavorite.findIndex(
            (item) => item.nom === source && item.vie === target
          );
          if (index !== -1) {
            arrFavorite.splice(index, 1);
            localStorage.favorite = JSON.stringify(arrFavorite);
            this.favoriteCheck = false;
          }

          // this.$emit("exceptionPopup", true);
        }
      } catch (error) {
        window.console.error(error);
      }
    },
    exceptionPopup(val) {
      if (val) {
        this.$emit("exceptionPopup", true);
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  watch: {
    async viShow(val) {
      this.favoriteCheck = false;

      try {
        let source = "";
        let target = "";
        for (let index = 0; index < val.length; index++) {
          source += val[index].pair.nom_text;
          target += val[index].pair.modern_text;

          if (index < val.length - 1) {
            source += "\n";
            target += "\n";
          }
        }

        if (this.$gAuth.isAuthorized) {
          if (val.length > 0) {
            let res = await this.$api.translation.checkFavorite({
              nom: source,
              vie: target,
            });

            if (res.status === 200 && res.data.length > 0) {
              this.favoriteCheck = true;
            } else {
              if (typeof res.data["err"] != "undefined") {
                if (res.data["err"].split(",")[0].trim() == "Token expired") {
                  this.$store.commit("removeemail");
                  this.$store.commit("removeusername");
                  this.$store.commit("removeimage");

                  this.$store.commit("logout");
                  this.$emit("exceptionPopup", true);
                } else {
                  window.console.error(res.data["err"]);
                }
              }
              this.favoriteCheck = false;
            }
          }
        } else {
          let favorite = localStorage.favorite;
          if (favorite) {
            let arrFavorite = JSON.parse(favorite);
            let index = arrFavorite.findIndex(
              (item) => item.nom === source && item.vie === target
            );
            if (index !== -1) {
              this.favoriteCheck = true;
            } else {
              this.favoriteCheck = false;
            }
          } else {
            this.favoriteCheck = false;
          }
        }
      } catch (error) {
        window.console.error(error);
      }
    },
  },
};
</script>

<!-- Auto resize textarea:
      https://medium0.com/@adamorlowskipoland/vue-auto-resize-textarea-3-different-approaches-8bbda5d074ce -->

<!-- Hide drop-down when click outsie
      https://stackoverflow.com/questions/46038989/how-to-hide-dropdown-menu-if-we-click-outside-the-menu-in-vuejs
      https://forum.vuejs.org/t/closing-all-dropdown-on-document-click/11217/2 -->

<!-- Download text in div
      https://stackoverflow.com/questions/22084698/how-to-export-source-content-within-div-to-text-html-file -->

<!-- Handle PDF file
      https://stackoverflow.com/questions/24272058/word-wrap-in-generated-pdf-using-jspdf 
      https://stackoverflow.com/questions/21923488/how-to-enable-utf-8-in-jspdf-library
      https://github.com/MrRio/jsPDF/issues/2640
      https://stackoverflow.com/questions/54627570/how-to-use-setfont-in-jspdf-with-utf-8 -->
