import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

import authentoken from './modules/authentoken';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authentoken,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) =>
          Cookies.set(key, value),
        removeItem: (key) => Cookies.remove(key),
      },
    })
  ],
});

export default store;