export default {
  language: {
    vi: "Vietnamese",
    en: "English",
  },
  button: {
    login: "Login",
    logout: "Logout",
    submit: "Submit",
  },
  translatetype: {
    text: "Text",
    document: "Document",
    image: "Image",
  },
  languagepanel: {
    nomscript: "Sino-Nom",
    vietnamese: "Vietnamese",
  },
  actions: {
    text: "DOWNLOAD AS TEXT",
    pdf: "DOWNLOAD AS PDF",
    review: "Review",
    edit: "Edit",
    uploadImage: "Upload image of nom script document",
    verticalUploadImage: "Upload an image for vertical translation",
    senceUploadImage: "Upload image of scene text Han Script",
    documentUploadImage: "Update document image "
  },
  extraactions: {
    feedback: "Send Feedbacks",
    history: "History",
    saved: "Saved",
    review: "Review",
    favorite: "Favorite",
    nomKeyboard: "Keystroke instruction"
  },
  exception: {
    title: "Notification",
    exceptionbody: "You must sign in to your Google account.",
    successbody: "Success!!!",
    button: "OK",
  },
  footer:
    "This work has been carried out by&nbsp;<a href='https://www.fit.hcmus.edu.vn' target='_blank'>the&nbsp;Faculty&nbsp;of&nbsp;Information&nbsp;Technology</a> and&nbsp;<a target='_blank' href='https://www.clc.hcmus.edu.vn/'>the&nbsp;Computational&nbsp;Linguistics&nbsp;Center</a> at&nbsp;<a href='https://www.hcmus.edu.vn/' target='_blank'>the&nbsp;University&nbsp;of&nbsp;Science&nbsp;-&nbsp;VNUHCM</a> under the scientific research project of&nbsp;<a href='https://dost.hochiminhcity.gov.vn' target='_blank'>the&nbsp;Department&nbsp;of&nbsp;Science&nbsp;and&nbsp;Technology&nbsp;in&nbsp;Ho&nbsp;Chi&nbsp;Minh&nbsp;City</a>",

    header: {
    title:"CLC Sino-Nom Translator"
  },
  tooltip: {
    copy: "Copy",
    edit: "Edit",
    download:"Download",
    review: "Review",
    save:"Save",
    unsave:"Unsave",
    keyboard:"Turn off Nom keyboard",
    nomKeyboard:"Turn on Nom keyboard",
    clear:"Clear",
    fit: "Faculty of Information Technology",
    clc: "Computational Linguistics Center"
  },
  verticalOCRStep: {
    step1: "Step 1: Upload an image",
    step2: "Step 2: Resize bounding box and click 'Crop' button (you can crop this image into many parts)",
    step3: "Step 3: Send to OCR",
    crop: "Crop",
    upload: "Upload Image",
    croppedImages: "Cropped Images",
    submit: "Send"
  },
  interpretation: {
    interpretation: "Interpretation (trial)",
    transcription: "Transcription",
    toggle: "Interpretation"
  }
};
