<template>
    <v-app>
        <Header />
        <!-- <router-link to="/">Go to Home</router-link>
    <router-link to="/about-us">Go to About Us</router-link> -->
        <v-main>
            <div>
                <v-container fluid no-gutters class="ma-0 pa-0">
                    <v-row no-gutters class="ma-0 pa-0">
                        <v-col :lg="lgMain" :md="mdMain" :sm="smMain">
                            <Translation @historyEmitAgain="historyEmitAgain" @documentEmitAgain="documentEmitAgain"
                                @reviewEmitAgain="reviewEmitAgain" @editPopupAgain="editPopupAgain"
                                @reviewPopupAgain="reviewPopupAgain" @favoriteEmitAgain="favoriteEmitAgain"
                                @exceptionPopup="exceptionPopup" @successPopup="successPopup" />
                        </v-col>
                        <v-col v-if="popup" :lg="lgExtra" :md="mdExtra" :sm="smExtra" class="d-flex justify-center">
                            <ExtraPane :popupTitle="popupTitle" :typePane="typePane" :sourceText="sourceText"
                                :targetText="targetText" :reviewList="reviewList" :historyList="historyList"
                                :favoriteList="favoriteList" @closeEmitted="closeEmit" @successPopup="successPopup" />
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <ExceptionDialog :dialog.sync="isException" />
            <SuccessDialog :successdialog.sync="isSuccess" />
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import Translation from "@src/views/Translation.vue";
import ExtraPane from "@src/views/ExtraPane.vue";
// import AuthenButton from "@src/views/AuthenButton.vue";
import ExceptionDialog from "@src/views/ExceptionDialog.vue";
import SuccessDialog from "@src/views/SuccessDialog.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "App",
    components: {
        Translation,
        ExtraPane,
        // AuthenButton,
        ExceptionDialog,
        SuccessDialog,
        Header,
        Footer
    },
    data: () => ({
        isInit: false,
        isSignIn: false,
        popup: false,
        popupTitle: "",
        sourceText: "",
        targetText: "",
        reviewList: [],
        historyList: [],
        favoriteList: [],
        typePane: -1,
        lgMain: 12,
        mdMain: 12,
        smMain: 12,
        lgExtra: 0,
        mdExtra: 0,
        smExtra: 0,
        isException: false,
        isSuccess: false,
        drawer: false,
    }),
    methods: {
        historyEmitAgain(val) {
            this.popupTitle = this.$t("extraactions.history");
            this.typePane = 4;
            this.popup = true;
            this.historyList = val;
            this.lgMain = 8;
            this.mdMain = 8;
            this.smMain = 12;
            this.lgExtra = 4;
            this.mdExtra = 4;
            this.smExtra = 12;
        },
        documentEmitAgain(val) {
            if (val) {
                this.popupTitle = this.$t("extraactions.document");
                this.typePane = 5;
                this.popup = true;
                this.lgMain = 8;
                this.mdMain = 8;
                this.smMain = 12;
                this.lgExtra = 4;
                this.mdExtra = 4;
                this.smExtra = 12;
            }
        },
        reviewEmitAgain(val) {
            this.popupTitle = this.$t("extraactions.review");
            this.typePane = 3;
            this.popup = true;
            this.reviewList = val;
            this.lgMain = 8;
            this.mdMain = 8;
            this.smMain = 12;
            this.lgExtra = 4;
            this.mdExtra = 4;
            this.smExtra = 12;
        },
        favoriteEmitAgain(val) {
            this.popupTitle = this.$t("extraactions.favorite");
            this.typePane = 6;
            this.popup = true;
            this.favoriteList = val;
            this.lgMain = 8;
            this.mdMain = 8;
            this.smMain = 12;
            this.lgExtra = 4;
            this.mdExtra = 4;
            this.smExtra = 12;
        },
        closeEmit(val) {
            if (val) {
                this.popupTitle = "";
                this.popup = false;
                this.lgMain = 12;
                this.mdMain = 12;
                this.smMain = 12;
                this.lgExtra = 0;
                this.mdExtra = 0;
                this.smExtra = 0;
            }
        },
        editPopupAgain({ sourceText, targetText }) {
            this.popupTitle = this.$t("actions.edit");
            this.typePane = 1;
            this.popup = true;
            this.sourceText = sourceText;
            this.targetText = targetText;
            this.lgMain = 8;
            this.mdMain = 8;
            this.smMain = 12;
            this.lgExtra = 4;
            this.mdExtra = 4;
            this.smExtra = 12;
        },
        reviewPopupAgain({ sourceText, targetText }) {
            this.popupTitle = this.$t("actions.review");
            this.typePane = 2;
            this.popup = true;
            this.sourceText = sourceText;
            this.targetText = targetText;
            this.lgMain = 8;
            this.mdMain = 8;
            this.smMain = 12;
            this.lgExtra = 4;
            this.mdExtra = 4;
            this.smExtra = 12;
        },
        exceptionPopup(val) {
            if (val) {
                this.isException = true;
            }
        },
        successPopup(val) {
            if (val) {
                this.isSuccess = true;
            }
        },
        tokenExpired(val) {
            if (val) {
                this.isException = true;
            }
        },

        closeDrawer() {
            this.drawer = true;
        },

        handleOutsideClick(event) {
            const drawerElement = this.$refs.drawer;

            if (
                this.drawer &&
                drawerElement != null &&
                drawerElement.$el &&
                !drawerElement.$el.contains(event.target)
            ) {
                this.closeDrawer();
            }
        },
    },
    created() {
        //do something after creating vue instance
        let that = this;
        let checkGauthLoad = setInterval(function () {
            that.isInit = that.$gAuth.isInit;
            that.isSignIn = that.$gAuth.isAuthorized;
            if (that.isInit) clearInterval(checkGauthLoad);
        }, 1000);
    },
    mounted() {
        document.body.addEventListener("click", this.handleOutsideClick);
    },

    beforeDestroy() {
        document.body.removeEventListener("click", this.handleOutsideClick);
    },
};
</script>

<style></style>