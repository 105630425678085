<template>
  <div>
    <div class="toolbar">
      <v-btn
        outlined
        color="blue darken-2"
        @click="selectTextTranslation"
        :class="{ active: translationType === 'text' }"
      >
        <v-icon left> mdi-ideogram-cjk </v-icon>
        {{ $t("translatetype.text") }}
      </v-btn>

      <v-btn
        outlined
        color="blue darken-2"
        @click="openDialog"
        :disabled="true"
      >
        <v-icon left> mdi-file-document-multiple-outline </v-icon>
        {{ $t("translatetype.document") }}
      </v-btn>

      <v-btn
        outlined
        color="blue darken-2"
        @click="selectImageTranslation"
        :class="{ active: translationType === 'image' }"
      >
        <v-icon left> mdi-image-outline </v-icon>
        {{ $t("translatetype.image") }}
      </v-btn>
    </div>

    <UploadImage :ocrDialog.sync="uploadOcrDialog" />

    <UploadFile
      :dialog.sync="uploadDialog"
      :multiple="true"
      @filesUploaded="processUpload($event)"
    />
  </div>
</template>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}
.toolbar {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.active {
  background-color: #e5f2ff;
}
</style>

<script>
import UploadFile from "@src/components/UploadFile.vue";
import UploadImage from "@src/components/UploadImage.vue";

export default {
  name: "TranslateType",
  data: () => ({
    uploadDialog: false,
    uploadOcrDialog: false,
    VUE_APP_OCR_KEY: "chunom",
  }),
  components: {
    UploadFile,
    UploadImage,
  },
  props: ["translationType"],
  methods: {
    selectTextTranslation() {
      this.$emit("changeTranslationType", "text");
    },
    selectImageTranslation() {
      // const key = sessionStorage.getItem("ocr_key");
      // if (key === this.VUE_APP_OCR_KEY) {
      //   this.$emit("changeTranslationType", "image");
      // } else {
      // const userInput = prompt("Please enter OCR KEY to continue:");
      // sessionStorage.setItem("ocr_key", userInput);
      // if (userInput === this.VUE_APP_OCR_KEY) {
      this.$emit("changeTranslationType", "image");
      // } else {
      // window.alert("Invalid key");
      // }
    },
    openOrcDialog() {
      this.uploadOcrDialog = true;
    },
    openDialog() {
      if (this.$gAuth.isAuthorized) {
        this.uploadDialog = true;
      } else {
        this.$emit("exceptionPopup", true);
      }
    },
    async processUpload(fileList) {
      try {
        if (this.$gAuth.isAuthorized) {
          let formData = new FormData();
          for (let index = 0; index < fileList.length; index++) {
            formData.append("files[" + index + "]", fileList[index]);
          }

          // Set state to ready for upload file(s)
          this.$store.commit("file");
          let res = await this.$api.translation.submitDocument(formData);

          if (res.status === 200 && res.data && res.data["success"]) {
            this.$store.commit("unfile");
            this.$emit("successPopup", true);
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
          }
        } else {
          this.$emit("exceptionPopup", true);
        }
      } catch (error) {
        window.console.log.error(error);
      }
    },
  },
};
</script>
