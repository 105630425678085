<template>
  <div class="custom-card">
    <!-- <v-card-text class="pa-0 ma-0">
      <v-spacer> </v-spacer>

      <p class="text-right font-italic">
        {{ $t("extraactions.feedback") }}
      </p>
    </v-card-text> -->

    <div class="actions">
      <v-btn outlined color="grey darken-2 " @click="historyHandler">
        <v-icon left> mdi-history </v-icon>
        {{ $t("extraactions.history") }}
      </v-btn>

      <v-btn outlined color="grey darken-2 " @click="favoriteHandler">
        <v-icon left> mdi-star-outline </v-icon>
        {{ $t("extraactions.saved") }}
      </v-btn>

      <!-- <div>
        <v-btn
          outlined
          color="grey darken-2 "
          @click="reviewHandler"
        >
          <v-icon left> mdi-message-processing-outline</v-icon>
          {{ $t("extraactions.review") }}
        </v-btn>
      </div> -->
    </div>
    <div class="flex-center language">
      <v-radio-group v-model="row" row class="hide-validation">
        <v-radio :label="$t('language.vi')" value="vi"></v-radio>
        <v-radio :label="$t('language.en')" value="en"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<style>
.hide-validation .v-messages {
  display: none;
}
</style>

<script>
export default {
  name: "ExtraActions",
  data: () => ({
    row: "vi",
  }),
  methods: {
    changeLang(val) {
      this.$i18n.locale = val;
    },
    async historyHandler() {
      try {
        if (this.$gAuth.isAuthorized) {
          let res = await this.$api.translation.getHistory();

          if (res.status === 200 && res.data) {
            this.$emit("historyEmit", res.data);
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
          }
        } else {
          // read history from local storage
          let history = localStorage.history;
          if (history) {
            let arrHistory = JSON.parse(history);
            this.$emit("historyEmit", arrHistory);
          } else {
            this.$emit("historyEmit", []);
          }
        }
      } catch (error) {
        window.console.log(error);
      }
    },
    async favoriteHandler() {
      try {
        if (this.$gAuth.isAuthorized) {
          let res = await this.$api.translation.getFavorite();

          if (res.status === 200 && res.data) {
            this.$emit("favoriteEmit", res.data);
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
          }
        } else {
          // read favorite from local storage
          let favorite = localStorage.favorite;
          if (favorite) {
            let arrFavorite = JSON.parse(favorite);
            this.$emit("favoriteEmit", arrFavorite);
          } else {
            this.$emit("favoriteEmit", []);
          }
        }
      } catch (error) {
        window.console.log(error);
      }
    },
    async documentHandler() {
      try {
        if (this.$gAuth.isAuthorized) {
          this.$emit("documentEmit", true);
        } else {
          this.$emit("exceptionPopup", true);
        }
      } catch (error) {
        window.console.log(error);
      }
    },
    async reviewHandler() {
      try {
        if (this.$gAuth.isAuthorized) {
          let res = await this.$api.translation.getReview();

          if (res.status === 200 && res.data) {
            this.$emit("reviewEmit", res.data);
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
          }
        } else {
          // this.$emit("exceptionPopup", true);

          let review = localStorage.review;
          if (review) {
            let arrreview = JSON.parse(review);
            this.$emit("reviewEmit", arrreview);
          }
        }
      } catch (error) {
        window.console.log(error);
      }
    },
  },
  watch: {
    row(val) {
      this.changeLang(val);
    },
  },
};
</script>

<style>
.custom-card {
  color: #222222;
  display: flex;
  flex-direction: row;
}
.actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.language {
  margin-left: 12px;
}
@media (max-width: 767px) {
  .custom-card {
    flex-direction: column;
  }
  .language {
    margin-left: 0;
    margin-top: 8px;
  }
}
</style>
