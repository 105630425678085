<template>
  <div>
    <div>
      <v-container fluid>
        <v-row v-if="showUploadImageBox" class="uploadImageBox">
          <v-btn outlined color="blue darken-2" @click="clickUploadScriptImage">
            <v-icon left> mdi-tray-arrow-up </v-icon>
            {{ $t("actions.uploadImage") }}
          </v-btn>
          <!-- 
          <v-btn
            outlined
            color="blue darken-2"
            @click="clickInputAPart"
            class="uploadButton"
          >
            <v-icon left> mdi-tray-arrow-up </v-icon>
            {{ $t("actions.verticalUploadImage") }}
          </v-btn> -->

          <v-btn
            outlined
            color="blue darken-2"
            @click="clickUploadDocumentImage"
          >
            <v-icon left> mdi-tray-arrow-up </v-icon>
            {{ $t("actions.documentUploadImage") }}
          </v-btn>

          <v-btn
            outlined
            color="blue darken-2"
            @click="clickUploadSenceImage"
            class="uploadButton"
          >
            <v-icon left> mdi-tray-arrow-up </v-icon>
            {{ $t("actions.senceUploadImage") }}
          </v-btn>

          <input
            ref="inputFile"
            type="file"
            accept="image/*"
            name="inputImageFile"
            id="inputImageFile"
            value="null"
            @change="handleImageUpload"
          />
        </v-row>

        <v-row :class="{ hide: showUploadImageBox }">
          <v-col class="ma-0 pa-0">
            <CustomNomTextArea
              v-if="nomToggle"
              @starState="starStateFunc"
              @nomTransState="nomTransFunc"
              @clickIcon="toggleNom"
              :highlightPosId="highlightPosId"
              :translationDirection="this.$props.translationDirection"
              @updateInterpretations="updateInterpretations"
            />
            <CustomSourceTextArea
              v-if="!nomToggle"
              @starState="starStateFunc"
              @nomTransState="nomTransFunc"
              @clickIcon="toggleNom"
              :highlightPosId="highlightPosId"
              @updateOCRFlag="updateOCRFlag"
              :ocrFlag="ocrFlag"
              :base64Images="base64Images"
              :translationType="$props.translationType"
              @updateUploadedImage="updateUploadedImage"
              @clearOCR="clearOCR"
              :translationDirection="this.$props.translationDirection"
              :typeOCR="typeOCR"
              @updateInterpretations="updateInterpretations"
            />
          </v-col>

          <v-divider vertical></v-divider>
          <v-col class="ma-0 pa-0 readonlyStyle">
            <CustomTargetTextArea
              :viShow="viShow"
              :viTransOutput="viTrans"
              :starGone="starGone"
              @substitutePosition="substitutePosition"
              @highlightPos="highlightPos"
              @editOutput="editOutput"
              @reviewOutput="reviewOutput"
              @exceptionPopup="exceptionPopup"
              :translationDirection="this.$props.translationDirection"
              :interpretations="interpretations"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <img :src="base64Images" alt="Uploaded Immage" /> -->
    <div class="preview-image-container" v-if="uploadedImage">
      <img :src="originImage" alt="origin Image" class="uploaded-imgs" />
      <img
        v-if="uploadedImage && !Array.isArray(uploadedImage) /*&& this.$props.translationDirection !== 'han-qn'*/"
        :src="uploadedImage"
        alt="Uploaded Image"
        class="uploaded-imgs"
      />
      <div v-if="Array.isArray(uploadedImage)" class="uploaded-imgs-container">
        <img
          v-for="(source, index) in uploadedImage"
          :key="index"
          :src="source"
          contain
          class="uploaded-imgs"
        />
      </div>

      <v-btn icon class="clear-image">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="clearOCR" v-on="on" left>mdi-close </v-icon>
            </template>
            <span>{{ $t("tooltip.clear") }}</span>
          </v-tooltip>
        </div>
      </v-btn>
    </div>

    <PreviewImageDialog
      @callOCR="callOCR"
      :isOpen="isShowPreviewDialog"
      :src="uploadedImage"
      @togglePreviewDialog="togglePreviewDialog"
    />
  </div>
</template>

<style>
.preview-image-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 600px;
}

.clear-image {
  position: absolute;
  right: 0;
  top: 0;
}

.uploaded-imgs-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.uploaded-imgs {
  object-fit: contain;
  max-width: 50%;
  width: auto;
  height: 100%;
  border: 1px solid #f5f5f5;
  flex: 1;
}

/* img {
  width: fit-content;
  height: fit-content;
  border: 1px solid gray;
} */

.hide {
  display: none;
}

/* .uploadImageBox:hover .uploadButton {
  background-color: #e5f2ff;
  transition: background-color 0.3s ease;
} */
.readonlyStyle {
  background-color: #f5f5f5;
}

.uploadImageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 20px;
  position: relative;
  padding: 30px 0;
}

/* .uploadImageBox button {
  margin-bottom: 20px;
} */

.uploadImageBox input[type="file"] {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

@media (max-width: 786px) {
  .preview-image-container {
    flex-direction: column;
    height: initial;
  }

  .uploaded-imgs {
    max-width: 100%;
    max-height: auto;
  }
}

/* 
.uploadImageBox input[type="file"]::before {
  content: "Upload Image";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #4285f4;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.uploadImageBox input[type="file"]:hover::before {
  background-color: #3367d6;
}

.uploadImageBox input[type="file"]:active::before {
  background-color: #1c3faa;
}


 */
</style>

<script>
import CustomNomTextArea from "@src/components/textarea/CustomNomTextArea.vue";
import CustomSourceTextArea from "@src/components/textarea/CustomSourceTextArea.vue";
import CustomTargetTextArea from "@src/components/textarea/CustomTargetTextArea.vue";
import PreviewImageDialog from "../PreviewImageDialog.vue";
import Pica from "pica";
export default {
  name: "ContentInput",
  data: () => ({
    starGone: false,
    highlightPosId: -1,
    viShow: [],
    viTrans: [],
    nomToggle: false,
    uploadedImage: null,
    originImage: null,
    showUploadImageBox: false,
    ocrFlag: false,
    base64Images: null,
    isShowPreviewDialog: false,
    typeOCR: "script", // script: van ban; sence: ngoai canh
    VUE_APP_OCR_KEY: "chunom",
    interpretations: [],
  }),
  props: ["translationType", "translationDirection"],

  components: {
    CustomNomTextArea,
    CustomSourceTextArea,
    CustomTargetTextArea,
    PreviewImageDialog,
  },
  watch: {
    translationType() {
      this.clearOCR();
      this.togglePreviewDialog(false);
      this.updateShowUploadImageBox(false);
      this.updateInterpretations([]);
    },
  },
  methods: {
    updateInterpretations(vals) {
      this.interpretations = vals;
    },
    clickInputAPart() {
      this.clearOCR();
      this.togglePreviewDialog(true);
    },
    clickUploadScriptImage() {
      this.typeOCR = "script";
      this.$emit("updateTranslationDirection", "nom-qn");
      // const key = sessionStorage.getItem("ocr_key");
      // if (key === this.VUE_APP_OCR_KEY) {
      this.clearOCR();
      this.$refs.inputFile.click();
      // } else {
      //   const userInput = prompt("Please enter OCR KEY to continue:");
      //   sessionStorage.setItem("ocr_key", userInput);
      //   if (userInput === this.VUE_APP_OCR_KEY) {
      //     this.clearOCR();
      //     this.$refs.inputFile.click();
      //   } else {
      //     window.alert("Invalid key");
      //   }
      // }
    },
    clickUploadSenceImage() {
      this.clearOCR();
      this.typeOCR = "sence";
      this.$emit("updateTranslationDirection", "nom-qn");
      this.$refs.inputFile.click();
    },
    clickUploadDocumentImage() {
      this.clearOCR();
      this.typeOCR = "document";
      this.$refs.inputFile.click();
      this.$emit("updateTranslationDirection", "han-qn");
    },
    togglePreviewDialog(val) {
      this.isShowPreviewDialog = val;
    },
    clearOCR() {
      this.base64Images = null;
      this.ocrFlag = false;
      this.showUploadImageBox = true;
      this.uploadedImage = null;
      this.originImage = null;
      this.updateInterpretations([]);
      this.$emit("updateTranslationDirection", "nom-qn");
    },
    updateUploadedImage(image) {
      this.uploadedImage = image;
    },
    updateBase64Image(code) {
      this.base64Images = code;
    },
    updateShowUploadImageBox(status) {
      this.showUploadImageBox = status;
    },
    updateOCRFlag(status) {
      this.ocrFlag = status;
    },

    starStateFunc(val) {
      if (!val) {
        this.viTrans = [];
        this.viShow = [];
      }
      this.starGone = val;
    },
    substitutePosition({ i, subi }) {
      // This updates value when user select subitem
      this.viShow[i] = this.viTrans[i][subi];
    },
    highlightPos(i) {
      this.highlightPosId = i;
    },
    async nomTransFunc(val) {
      if (this.$props.translationDirection === "nom-qn") {
        if (val.length > 0) {
          let res = await this.$api.translation.nomtranslation({
            nom_text: val,
          });

          if (res.status === 200 && res.data) {
            this.viTrans = res.data["sentences"];

            this.viShow = []; // Remove old content
            this.viTrans.forEach((ele) => {
              this.viShow.push(ele[0]);
            });
            let source = "";
            let target = "";
            for (let index = 0; index < this.viShow.length; index++) {
              source += this.viShow[index].pair.nom_text;
              target += this.viShow[index].pair.modern_text;

              if (index < this.viShow.length - 1) {
                source += "\n";
                target += "\n";
              }
            }

            try {
              if (this.$gAuth.isAuthorized) {
                let historyRes = await this.$api.translation.submitHistory({
                  nom: source,
                  vie: target,
                });

                if (historyRes.status === 200 && historyRes.data["success"]) {
                  window.console.log("checking...");
                } else {
                  if (typeof res.data["err"] != "undefined") {
                    if (
                      res.data["err"].split(",")[0].trim() == "Token expired"
                    ) {
                      this.$store.commit("removeemail");
                      this.$store.commit("removeusername");
                      this.$store.commit("removeimage");

                      this.$store.commit("logout");
                      this.$emit("exceptionPopup", true);
                    } else {
                      window.console.error(res.data["err"]);
                    }
                  }
                }
              } else {
                let history = localStorage?.history;
                // get old history from local storage and append new history
                if (history) {
                  let previousHistory = JSON.parse(history);
                  if (Array.isArray(previousHistory)) {
                    let newHistory = [
                      ...previousHistory,
                      {
                        nom: source,
                        vie: target,
                      },
                    ];
                    // save the 100 oldest history (because local storage only have 5MB)

                    if (newHistory.length > 100) {
                      newHistory = newHistory.slice(newHistory.length - 100);
                    }
                    localStorage.history = JSON.stringify(newHistory);
                  }
                }
                // create new history in local storage
                else {
                  let newHistory = JSON.stringify([
                    {
                      nom: source,
                      vie: target,
                    },
                  ]);
                  localStorage.history = newHistory;
                }
              }
            } catch (error) {
              window.console.error(error);
            }
          } else {
            if (typeof res.data["err"] != "undefined") {
              if (res.data["err"].split(",")[0].trim() == "Token expired") {
                this.$store.commit("removeemail");
                this.$store.commit("removeusername");
                this.$store.commit("removeimage");

                this.$store.commit("logout");
                this.$emit("exceptionPopup", true);
              } else {
                window.console.error(res.data["err"]);
              }
            }
          }

          try {
            const response = await this.$api.translation.interpretation({
              text: val,
            });
            const result = response.data.result;
            this.updateInterpretations(result);
          } catch (error) {
            console.error("Lỗi khi gọi API:", error);
          }
        }
      } else if (this.$props.translationDirection === "qn-nom") {
        try {
          const response = await this.$api.translation.nationalTranslation({
            text: val,
          });

          const result = response.data.result;
          this.viShow = [
            {
              pair: {
                nom_text: val,
                modern_text: result,
              },
            },
          ];
        } catch (error) {
          console.error("Lỗi khi gọi API:", error);
        }
      } else if (this.$props.translationDirection === "han-qn") {
        try {
          const response = await this.$api.translation.documentInterpretation({
            text: val,
          });

          const result = response.data.result.result_sv.join('\n');
          const interpretation = response.data.result.result_vi;
          this.updateInterpretations(interpretation);
          this.viShow = [
            {
              pair: {
                nom_text: val,
                modern_text: result,
              },
            },
          ];
        } catch (error) {
          console.error("Lỗi khi gọi API:", error);
        }
      }
    },
    editOutput({ sourceText, targetText }) {
      this.$emit("editPopup", {
        sourceText: sourceText,
        targetText: targetText,
      });
    },
    reviewOutput({ sourceText, targetText }) {
      this.$emit("reviewPopup", {
        sourceText: sourceText,
        targetText: targetText,
      });
    },
    exceptionPopup(val) {
      if (val) {
        this.$emit("exceptionPopup", true);
      }
    },
    toggleNom() {
      this.nomToggle = !this.nomToggle;
    },
    callOCR(base64Images) {
      const base64Codes = base64Images.map((image) =>
        image.substring(image.indexOf("base64") + 7)
      );

      this.updateBase64Image(base64Codes);
      this.updateUploadedImage(base64Images);
      this.updateOCRFlag(true);
      this.updateShowUploadImageBox(false);

      this.togglePreviewDialog(false);
    },
    handleImageUpload(event) {
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.onload = async (e) => {
    const base64Images = e.target.result;
    const base64Code = base64Images.substring(
      base64Images.indexOf(",") + 1
    );
    console.log("file.size", file.size);

    // Check if the image size is greater than 2.7MB
    if (file.size > 2764800) { 
      console.log("Scale");
      // Scale down the image
      const scaledImageBlob = await this.scaleImage(file);

      // Update the base64 image with the scaled image
      const scaledReader = new FileReader();
      scaledReader.onload = (scaledEvent) => {
        this.originImage = scaledEvent.target.result;
        const base64Code = this.originImage.substring(
          this.originImage.indexOf(",") + 1
        );
        this.updateBase64Image(base64Code);
        this.updateOCRFlag(true);
        this.updateShowUploadImageBox(false);
        // this.togglePreviewDialog(true);
      };
      scaledReader.readAsDataURL(scaledImageBlob);
    } else {
      // Image size is within the limit, no need to scale
      this.originImage = base64Images;
      this.updateBase64Image(base64Code);
      this.updateOCRFlag(true);
      this.updateShowUploadImageBox(false);
      // this.togglePreviewDialog(true);
    }
  };

  reader.readAsDataURL(file);
},

async scaleImage(imageFile) {
  const pica = new Pica();
  const img = document.createElement("img");

  // Tạo một Promise để đợi sự kiện onload của hình ảnh
  const loadImagePromise = new Promise((resolve) => {
    img.onload = resolve;
  });

  // Read image data into the img element
  const imageDataUrl = await this.readFileAsync(imageFile);
  img.src = imageDataUrl;

  // Đợi cho sự kiện onload của hình ảnh
  await loadImagePromise;

  // Calculate scale ratio to reduce size to 5MB
  const scaleRatio = (2764800 / imageFile.size).toFixed(2); // 960 x 960 x 3
  console.log("scaleRatio", scaleRatio);
  // Create canvas to hold the scaled image
  const canvas = document.createElement("canvas");
  canvas.width = img.width * scaleRatio;
  canvas.height = img.height * scaleRatio;

  // Use Pica to scale the image
  await pica.resize(img, canvas);

  // Convert canvas to base64 data
  console.log("scaleRatio", scaleRatio);
  const scaledImageDataUrl = canvas.toDataURL("image/jpeg", scaleRatio);

  // Convert base64 to Blob
  const scaledBlob = this.base64ToBlob(scaledImageDataUrl);

  console.log("scaledBlob", scaledBlob);

  return scaledBlob;
},

// Đảm bảo phương thức readFileAsync được định nghĩa trong cùng một scope hoặc trong đối tượng Vue
async readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
},
    // Phương thức base64ToBlob được định nghĩa trong cùng một scope hoặc trong đối tượng Vue
    base64ToBlob(base64) {
      const binaryString = window.atob(base64.split(",")[1]);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "image/jpeg" });
    },

  },
};
</script>