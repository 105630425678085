<template>
  <v-app>
    <Header />
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="languageOptions"
              label="Chọn ngôn ngữ / Choose Language"
              @change="handleChangeLanguage"
            ></v-select>
          </v-col>
        </v-row>
        <pdf
          class="pdf-content"
          v-for="i in numPages"
          :key="i"
          :src="getPdfSrc"
          :page="i"
        ></pdf>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import pdf from "vue-pdf";

export default {
  name: "Instruction1",
  components: {
    Header,
    Footer,
    pdf,
  },
  data() {
    return {
      selectedLanguage: "vi",
      languageOptions: [
        { text: "Tiếng Việt", value: "vi" },
        { text: "English", value: "en" },
      ],
      numPages: 0,
    };
  },
  computed: {
    getPdfSrc() {
      return this.selectedLanguage === "en"
        ? "/documentation-en.pdf"
        : "/documentation.pdf";
    },
  },
  methods: {
    handleChangeLanguage() {
      // Xử lý khi ngôn ngữ được thay đổi
      this.loadPdf();
    },
    async loadPdf() {
      const loadingTask = pdf.createLoadingTask(this.getPdfSrc);
      const pdfInstance = await loadingTask.promise;

      this.numPages = pdfInstance.numPages;
    },
  },
  mounted() {
    this.loadPdf();
  },
};
</script>

<style scoped>
.pdf-content {
  width: 100%;
  height: auto;
}
</style>
