import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import vi from "./vi";

Vue.use(VueI18n);

const messages = { en, vi };

export default new VueI18n({
  locale: "vi",
  messages,
});
