<template>
  <div>
    <div class="wrapper">
      <div class="stack_container">
        {{ loadingText }}
        <v-textarea
          v-model="inputText"
          class="custom-style"
          v-bind:id="
            this.$props.translationDirection === 'nom-qn' ||
            this.$props.translationDirection === 'han-qn'
              ? 'nom-input-area'
              : ''
          "
          color="info"
          @keyup="charCount()"
          ref="highlightText"
          auto-grow
        />
        <!-- <ResizeAuto ref="resizeComponent">
          <template v-slot:default="{ resize }">
            <textarea
              v-model="inputText"
              class="custom-style"
              color="info"
              @input="resize"
              @change="resize"
              @focus="resize"
              @keyup="charCount()"
              ref="highlightText"
            >
            </textarea>
          </template>
        </ResizeAuto> -->
        <div
          v-html="highlightText"
          class="custom-style-highlight"
          aria-hidden="true"
          v-bind:id="
            this.$props.translationDirection === 'nom-qn' ||
            this.$props.translationDirection === 'han-qn'
              ? 'nom-highlight-text'
              : ''
          "
        ></div>
      </div>

      <v-btn icon class="setPosition">
        <div v-if="hidden">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="clearText" v-on="on">{{ icon.clear }}</v-icon>
            </template>
            <span>{{ $t("tooltip.clear") }}</span>
          </v-tooltip>
        </div>
      </v-btn>
    </div>

    <div class="widget-wrapper">
      <Counter
        :isNom="false"
        :counterChar="totalcharacter"
        @clickIcon="$emit('clickIcon')"
      />
    </div>
  </div>
</template>

<style>
.full-height {
  height: 100%;
}

.wrapper {
  height: 100%;
  position: relative;
  padding: 20px 56px 20px 24px;
}

.widget-wrapper {
  margin-top: auto;
  padding: 0 12px 8px 11px;
}

.stack_container {
  width: 100%;
  display: flex;
  -moz-box-flex: 1;
  flex: 1;
}

.custom-style {
  width: 100%;
  border: none;
  font-size: 18px;
  line-height: 28px;
  background: transparent;
  color: #3c4043;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
  z-index: 1;
}

.custom-style-highlight {
  width: 100%;
  border: none;
  margin-left: -100%;
  font-size: 18px;
  line-height: 28px;
  color: #3c4043;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
  padding-top: 4px;
}

.highlight-hover {
  cursor: pointer;
  background: #d2e3fc;
}

.setPosition {
  position: absolute;
  top: 0;
  right: 5px;
  margin-top: 17px;
}

.v-input__slot::before {
  display: none;
}

.v-input__slot:after {
  border: none !important;
  outline: none;
}
#nom-highlight-text {
  font-family: "MyFont", sans-serif;
}
</style>

<script>
// import ResizeAuto from "@src/components/textarea/ResizeAuto.vue";
import Counter from "@src/components/textarea/widget/Counter.vue";

export default {
  name: "CustomTextArea",
  data: () => ({
    inputText: "",
    totalcharacter: 0,
    icon: {
      clear: "mdi-close",
    },
    highlightText: "",
    loadingText: "",
  }),
  props: [
    "starGone",
    "highlightPosId",
    "nomTextOCR",
    "ocrFlag",
    "base64Images",
    "translationType",
    "translationDirection",
    "typeOCR",
  ],
  components: {
    // ResizeAuto,
    Counter,
  },
  methods: {
    updateLoadingText(status) {
      if (status) {
        this.loadingText = "Loading...";
      } else {
        this.loadingText = "";
      }
    },
    charCount() {
      this.totalcharacter = this.inputText.length;
    },
    clearText() {
      if (this.translationType === "image") {
        this.$emit("clearOCR");
      }
      this.inputText = "";
      this.totalcharacter = 0;
      this.$emit("starState", false);
      this.$emit("updateInterpretations", []);
    },
    updateText() {
      if (this._props.value) {
        this.inputText = this._props.value;
        this.charCount();
      }
    },
    updateInput(newInput) {
      this.inputText = newInput;
      this.$refs.highlightText.focus();
      this.charCount();
    },
    async callOCRFull(base64Image, type = "script") {
      if (type == "script") {
        try {
          const response = await this.$api.translation.nomTextOCR({
            base64Data: base64Image,
          });

          const result = response.data.result;
          const text = result.ocrResult.join("\n").toString();
          const boudingImage = "data:image/png;base64," + result.imageBase64;

          this.$emit("updateUploadedImage", boudingImage);
          this.updateInput(text);
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        } catch (error) {
          console.error(error);
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        }
      }
      //type == sence
      else if (type == "sence") {
        const response = await this.$api.translation.senceTextOCR({
          base64Data: base64Image,
        });

        const data = response.data.result;

        if (data) {
          const text = data.ocrNom.join("\n").toString();
          let imgs = null;
          if (Array.isArray(data.imageBase64)) {
            imgs = [];
            data.imageBase64.forEach((img) => {
              let mappedImg = "data:image/png;base64," + img;
              imgs.push(mappedImg);
            });
          } else {
            imgs = "data:image/png;base64," + data.imageBase64;
          }

          this.$emit("updateUploadedImage", imgs);
          this.updateInput(text);
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        } else {
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        }
      }
      //type == document
      else {
        try {
          const response = await this.$api.translation.documentOCR({
            base64Data: base64Image,
          });
          const result = response.data.result;
          const text = result.ocrResult.join("\n").toString();
          const boudingImage = "data:image/png;base64," + result.imageBase64;

          this.$emit("updateUploadedImage", boudingImage);
          this.updateInput(text);

          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        } catch (error) {
          console.error(error);
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        }
      }
    },
    callOCRAPart(base64Images) {
      fetch(
        " https://nom-ocr-hcmus--65t7miy.nicetree-11fa6ea6.southeastasia.azurecontainerapps.io/nom-recognition",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ base64Data: base64Images }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.updateLoadingText(false);

          const text = data.recognitionResult.join("\n").toString();
          this.updateInput(text);
          this.$emit("updateOCRFlag", false);
        })
        .catch((error) => {
          // Xử lý lỗi
          console.error(error);
          this.$emit("updateOCRFlag", false);
          this.updateLoadingText(false);
        });
    },

    callTransOCR(base64Images, type = "script") {
      if (!Array.isArray(base64Images)) {
        this.callOCRFull(base64Images, type);
      } else {
        this.callOCRAPart(base64Images);
      }
    },
  },
  computed: {
    hidden() {
      if (this.inputText.length > 0) {
        let payload = this.inputText.trim();
        if (payload.length > 500) {
          payload = payload.slice(0, 500);
          this.updateInput(payload);
        }
        this.$emit("nomTransState", payload);
        this.$emit("starState", true);
        return true;
      } else {
        this.$emit("starState", false);
        return false;
      }
    },
  },

  watch: {
    ocrFlag(status) {
      if (status) {
        this.updateLoadingText(true);
        this.callTransOCR(this._props.base64Images, this._props.typeOCR);
      }
    },
    highlightPosId(newVal) {
      var inputTextArray = this.inputText.trim().split(/\. |, |\n|。|、/);

      if (newVal != -1) {
        this.highlightText = "<span>";

        for (let index = 0; index < inputTextArray.length; index++) {
          if (index == newVal) {
            this.highlightText += '<span class="highlight-hover">';
            this.highlightText += inputTextArray[index];
            this.highlightText += "</span>";
          } else {
            this.highlightText += inputTextArray[index];
          }

          if (index != inputTextArray.length) {
            this.highlightText += "\n";
          }
        }

        this.highlightText += "</span>";
      } else {
        this.highlightText = "";
      }
    },
    translationType(type, oldType) {
      if (type !== oldType) {
        this.clearText();
      }
    },
  },
};
</script>

<!-- Auto resize textarea:
      https://medium0.com/@adamorlowskipoland/vue-auto-resize-textarea-3-different-approaches-8bbda5d074ce -->

<!--  Creating an Editable Textarea That Supports Syntax-Highlighted Code
      https://css-tricks.com/creating-an-editable-textarea-that-supports-syntax-highlighted-code/-->
