<template>
  <v-card flat width="100%" class="d-flex justify-end align-center">
    <span color="#5f6368" :class="[isOver ? errorClass : activeClass]">
      {{ counterChar }} / 500
    </span>
    <v-btn-toggle v-model="toggle" tile>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn depressed color="white" @click="$emit('clickIcon')" v-on="on">
            <v-icon>mdi-keyboard-outline</v-icon>
          </v-btn>
        </template>
        <span>{{
          isNom ? $t("tooltip.keyboard") : $t("tooltip.nomKeyboard")
        }}</span>
      </v-tooltip>
    </v-btn-toggle>
  </v-card>
</template>

<style>
.custom-counter-style {
  color: #5f6368;
  font-size: 12px;
}

.custom-counter-error-style {
  color: red;
  font-size: 12px;
}
</style>

<script>
export default {
  name: "Counter",
  data: () => ({
    toggle: null,
    activeClass: "custom-counter-style",
    errorClass: "custom-counter-error-style",
  }),
  props: {
    counterChar: Number,
    isNom: {
      type: Boolean, // Update the type to Boolean constructor function
      required: true,
    },
  },
  emits: ["clickIcon"],
  computed: {
    isOver() {
      if (this.counterChar > 500) {
        return true;
      }
      return false;
    },
  },
};
</script>
