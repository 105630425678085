import axios from "axios";

export default {
    async nomtranslation(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "nom_translation/90/1", payload)
    },
    async submitModification(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_edit/", payload)
    },
    async submitHistory(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_history/", payload)
    },
    async submitReview(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_review/", payload)
    },
    async submitFavorite(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_favorite/", payload)
    },
    async submitDocument(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_document/", payload)
    },
    async submitImage(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "user_images/", payload)
    },
    async getHistory() {
        return await axios.get(process.env.VUE_APP_NOM_API + "user_history/")
    },
    async getReview() {
        return await axios.get(process.env.VUE_APP_NOM_API + "user_review/")
    },
    async getFavorite() {
        return await axios.get(process.env.VUE_APP_NOM_API + "user_favorite/")
    },
    async getDocument() {
        return await axios.get(process.env.VUE_APP_NOM_API + "user_document/")
    },
    async deleteFavorite(payload) {
        return await axios.delete(process.env.VUE_APP_NOM_API + "user_favorite/", {
            data: payload
        })
    },
    async checkFavorite(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "check_favorite/", payload)   
    },
    async interpretation(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "interpretation/", payload)   
    },
    async nationalTranslation(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "national_translation/", payload)   
    },
    async senceTextOCR(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "sence-text-ocr/", payload)   
    },
    async nomTextOCR(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "nom-text-ocr/", payload)   
    },
    async documentOCR(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "document-ocr/", payload)   
    },
    async documentInterpretation(payload) {
        return await axios.post(process.env.VUE_APP_NOM_API + "document-interpretation/", payload)   
    },
}
