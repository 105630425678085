//NavigationDrawer.vue
<template>
  <v-navigation-drawer v-model="drawer" app @click.stop="handleDrawerClick">
    <v-list>
      <router-link to="/">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Trang chủ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <!-- Add other navigation items as needed -->
    </v-list>
    <v-list>
      <router-link to="/about-us">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Về chúng tôi</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <!-- Add other navigation items as needed -->
    </v-list>
    <v-list>
      <router-link to="/instruction-1">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Hướng dẫn bộ gõ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <!-- Add other navigation items as needed -->
    </v-list>
    <v-list>
      <router-link to="/instruction-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Hướng dẫn sử dụng</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <!-- Add other navigation items as needed -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    drawer: Boolean,
  },
  methods: {
    handleDrawerClick() {
      // Do nothing when the drawer is clicked to prevent immediate closure
    },
    navigateTo(routeName) {
      console.log(routeName);
      // Use Vue Router to navigate to the specified route
      this.$router.push({ name: routeName });
      // Close the drawer after navigation if needed
      this.$emit("closeDrawer");
    },
  },
};
</script>
