<template>
  <v-card flat width="100%" color="#f5f5f5" class="d-flex justify-end">
    <v-btn-toggle v-model="toggle" v-show="widgetGone">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="copy" v-on="on">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.copy") }}</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="edit" v-on="on">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.edit") }}</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <v-icon>mdi-download-box-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="txtDownload">
                  <v-list-item-title>{{
                    $t("actions.text")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="pdfDownload">
                  <v-list-item-title>{{ $t("actions.pdf") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <span>{{ $t("tooltip.download") }}</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="review" v-on="on">
            <v-icon>mdi-message-text-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.review") }}</span>
      </v-tooltip> -->
    </v-btn-toggle>
  </v-card>
</template>

<style>
.v-btn-toggle > .v-btn.v-btn {
  border-style: none !important;
}
button {
  box-shadow: none !important;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
</style>

<script>
export default {
  name: "Actions",
  data: () => ({
    toggle: null,
    hide: false,
  }),
  props: {
    widgetGone: Boolean,
  },
  methods: {
    copy() {
      this.$emit("copyCmd", true);
    },
    edit() {
      if (this.$gAuth.isAuthorized) {
        this.$emit("editCmd", true);
      } else {
        this.$emit("exceptionPopup", true);
      }
    },
    txtDownload() {
      this.$emit("txtCmd", true);
    },
    pdfDownload() {
      this.$emit("pdfCmd", true);
    },
    review() {
      if (this.$gAuth.isAuthorized) {
        this.$emit("reviewCmd", true);
      } else {
        this.$emit("exceptionPopup", true);
      }
    },
  },
};
</script>
