var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-area-container"},[_c('div',{staticClass:"wrapper readonlyBackground",class:this.$props.translationDirection === 'nom-qn' ||
      this.$props.translationDirection === 'han-qn'
        ? ''
        : 'nom-font'},[_c('div',{staticClass:"custom-style-output",attrs:{"id":"copiedText"}},_vm._l((_vm.viShow),function(item,i){return _c('span',{key:i,staticClass:"span-hover",on:{"click":function($event){return _vm.menuDropdown(i)},"mouseover":function($event){return _vm.posId(i)},"mouseleave":function($event){return _vm.posId(-1)}}},[_c('span',{domProps:{"textContent":_vm._s(item.pair.modern_text)}}),_c('br'),(i === _vm.isShowOtherTranslation)?_c('div',{staticClass:"menu-dropdown"},_vm._l((_vm.viTransOutput[i]),function(subitem,subi){return _c('div',{key:subi,staticClass:"item-dropdown",class:[
              subi === _vm.viTransOutput[i].length - 1
                ? 'item-dropdown-no-border-bottom'
                : '',
            ],on:{"click":function($event){return _vm.substitute(i, subi)}}},[(
                subitem.pair.modern_text.trim() ==
                  item.pair.modern_text.trim() &&
                subitem.pair.nom_text.trim() == item.pair.nom_text.trim()
              )?_c('div',{staticClass:"subitem-tick"},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"}})])]):_c('div',{staticClass:"subitem-tick"}),_c('div',{staticClass:"subitem-content"},[_c('div',{staticClass:"subitem-trg",domProps:{"textContent":_vm._s(subitem.pair.modern_text)}}),_c('div',{staticClass:"subitem-src",domProps:{"textContent":_vm._s(subitem.pair.nom_text)}})])])}),0):_vm._e()])}),0),(this.$props.interpretations.length > 0 && _vm.viShow.length > 0)?_c('div',{staticClass:"custom-style-interpretations"},[_c('p',[_vm._v(_vm._s(_vm.$t("interpretation.interpretation"))+":")]),_vm._l((this.$props.interpretations),function(item,i){return _c('span',{key:i,staticClass:"span-hover"},[_c('span',{domProps:{"textContent":_vm._s(item)}}),_c('br'),(i === _vm.isShowOtherTranslation)?_c('div',{staticClass:"menu-dropdown"},_vm._l((_vm.viTransOutput[i]),function(subitem,subi){return _c('div',{key:subi,staticClass:"item-dropdown",class:[
              subi === _vm.viTransOutput[i].length - 1
                ? 'item-dropdown-no-border-bottom'
                : '',
            ],on:{"click":function($event){return _vm.substitute(i, subi)}}},[(
                subitem.pair.modern_text.trim() ==
                  item.pair.modern_text.trim() &&
                subitem.pair.nom_text.trim() == item.pair.nom_text.trim()
              )?_c('div',{staticClass:"subitem-tick"},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"}})])]):_c('div',{staticClass:"subitem-tick"}),_c('div',{staticClass:"subitem-content"},[_c('div',{staticClass:"subitem-trg",domProps:{"textContent":_vm._s(subitem.pair.modern_text)}}),_c('div',{staticClass:"subitem-src",domProps:{"textContent":_vm._s(subitem.pair.nom_text)}})])])}),0):_vm._e()])})],2):_vm._e(),_c('v-btn',{staticClass:"setPosition",attrs:{"icon":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.starGone),expression:"starGone"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(!_vm.favoriteCheck)?_c('v-icon',_vm._g({on:{"click":_vm.favorite}},on),[_vm._v(_vm._s(_vm.icon.starOutline))]):_c('v-icon',_vm._g({attrs:{"color":"yellow darken-2"},on:{"click":_vm.unfavorite}},on),[_vm._v(_vm._s(_vm.icon.star))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.favoriteCheck ? _vm.$t("tooltip.unsave") : _vm.$t("tooltip.save")))])])],1)])],1),_c('div',{staticClass:"widget-wrapper readonlyBackground"},[_c('Actions',{attrs:{"widgetGone":_vm.starGone},on:{"copyCmd":_vm.copyCmd,"editCmd":_vm.editCmd,"reviewCmd":_vm.reviewCmd,"txtCmd":_vm.txtCmd,"pdfCmd":_vm.pdfCmd,"exceptionPopup":_vm.exceptionPopup}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }