<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {

  },
}
</script>

<style lang="scss" scoped></style>
