<template>
  <div>
    <div class="wrapper">
      <div class="stack_container">
        <!-- <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              v-bind:id="
                this.$props.translationDirection ? 'NomInputArea' : ''
              "
              v-model="inputText"
              class="custom-nom-style input-area"
              color="info"
              @input="resize"
              @keyup="charCount()"
              ref="highlightText"
            >
            </textarea>
          </template>
        </ResizeAuto> -->
        <textarea
          v-bind:id="
            this.$props.translationDirection === 'nom-qn' ||
            this.$props.translationDirection === 'han-qn'
              ? 'NomInputArea'
              : ''
          "
          v-model="inputText"
          class="custom-nom-style input-area"
          color="info"
          @input="resize"
          @keyup="charCount()"
          ref="highlightText"
        >
        </textarea>

        <div class="suggestion">
          <ul v-if="candidate.length > 0" class="custom-style">
            <li v-for="cand in candidate" :key="cand" class="">{{ cand }}</li>
          </ul>
        </div>

        <div
          v-html="highlightText"
          class="custom-style-highlight"
          aria-hidden="true"
        ></div>
      </div>

      <v-btn icon class="setPosition">
        <div v-if="hidden">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="clearText" v-on="on">{{ icon.clear }}</v-icon>
            </template>
            <span>{{ $t("tooltip.clear") }}</span>
          </v-tooltip>
        </div>
      </v-btn>
    </div>

    <div class="widget-wrapper">
      <span class="widget-text">
        <a :href="getDocumentLink()" target="_blank">{{
          $t("extraactions.nomKeyboard")
        }}</a>
      </span>
      <Counter
        :isNom="true"
        :counterChar="totalcharacter"
        @clickIcon="$emit('clickIcon')"
      />
    </div>
  </div>
</template>

<style>
.wrapper {
  height: 100%;
  position: relative;
  padding: 20px 56px 20px 24px;
}

.widget-wrapper {
  display: flex;
  margin-top: auto;
  padding: 0 12px 8px 11px;
  align-items: center;
}

.widget-text {
  width: 100%;
  margin-left: 6px;
  padding: 0;
}

.suggestion {
  display: flex;
  width: 50%;
  height: 100%;
  background-color: inherit;
}

ul {
  list-style-type: none;
}

.stack_container {
  width: 100%;
  display: flex;
  -moz-box-flex: 1;
  flex: 1;
}

.custom-nom-style {
  width: 50%;
  border: none;
  font-size: 18px;
  line-height: 28px;
  background: transparent;
  color: #3c4043;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
  z-index: 1;
}

.custom-style-highlight {
  width: 100%;
  border: none;
  margin-left: -100%;
  font-size: 18px;
  line-height: 28px;
  color: #3c4043;
  overflow: auto hidden;
  resize: none;
  padding: 0;
  outline: none;
  white-space: pre-wrap;
}

.highlight-hover {
  cursor: pointer;
  background: #d2e3fc;
}

.setPosition {
  position: absolute;
  top: 0;
  right: 5px;
  margin-top: 17px;
}
</style>

<script>
// import ResizeAuto from "@src/components/textarea/ResizeAuto.vue";
import Counter from "@src/components/textarea/widget/Counter.vue";

export default {
  name: "CustomNomTextArea",
  data: () => ({
    inputText: "",
    totalcharacter: 0,
    icon: {
      clear: "mdi-close",
    },
    highlightText: "",
    completion: "",
    candidate: [],
  }),
  props: {
    starGone: Boolean,
    highlightPosId: Number,
    translationDirection: String,
  },
  components: {
    // ResizeAuto,
    Counter,
  },

  methods: {
    getDocumentLink() {
      const lang = this.$i18n.locale; // Get the current language mode
      if (lang === "en") {
        return "/documentation-en.pdf"; // Link to the English version of the file
      }
      return "/documentation.pdf";
    },
    charCount() {
      this.totalcharacter = this.inputText.length;
    },
    clearText() {
      if (this.inputText.length > 0) {
        this.inputText = "";
        this.totalcharacter = 0;
        this.$emit("starState", false);
        this.completion = "";
        this.candidate = [];
      }
      this.$emit("updateInterpretations", []);
    },
    resize(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
  },
  computed: {
    hidden() {
      if (this.inputText.length > 0 && this.inputText.length <= 500) {
        this.$emit("nomTransState", this.inputText.trim());
        this.$emit("starState", true);
        return true;
      } else {
        this.$emit("starState", false);
        return false;
      }
    },
  },
  watch: {
    highlightPosId(newVal) {
      var inputTextArray = this.inputText.trim().split(/\. |, |\n|。|、/);

      if (newVal != -1) {
        this.highlightText = "<span>";

        for (let index = 0; index < inputTextArray.length; index++) {
          if (index == newVal) {
            this.highlightText += '<span class="highlight-hover">';
            this.highlightText += inputTextArray[index];
            this.highlightText += "</span>";
          } else {
            this.highlightText += inputTextArray[index];
          }

          if (index != inputTextArray.length) {
            this.highlightText += "\n";
          }
        }

        this.highlightText += "</span>";
      } else {
        this.highlightText = "";
      }
    },
  },
  mounted: function () {
    window.BodyOnLoad();

    // Define events for main input box
    let mainInputArea = document.getElementById("NomInputArea");
    mainInputArea.onkeyup = window.ImeKeyUp;
    mainInputArea.onkeydown = window.ImeKeyDown;
    mainInputArea.onkeypress = window.ImeKeyPress;

    setInterval(() => {
      this.inputText = mainInputArea.value;
      this.charCount();
      this.completion = window.IME.Comp.value;
      this.candidate = window.IME.Cand.value.split(/\r?\n/).filter((e) => e);
    }, 100);
  },
};
</script>

<!-- Auto resize textarea:
      https://medium0.com/@adamorlowskipoland/vue-auto-resize-textarea-3-different-approaches-8bbda5d074ce -->

<!--  Creating an Editable Textarea That Supports Syntax-Highlighted Code
      https://css-tricks.com/creating-an-editable-textarea-that-supports-syntax-highlighted-code/-->
