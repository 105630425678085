import axios from "axios";
import store from "@src/store";

axios.interceptors.request.use(
  async function (config) {
      if (store.getters.isauthen) {
        config.headers["Authorization"] = "Bearer " + store.getters.gettoken;
      }

      if (store.getters.upload) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(
  async function (response) {
      return response;
    },
    async function (error) {
      switch (error?.response?.status) {
        case 401:
          store.commit('logout');
          break;
          // case 403:
          //     return _instance.$toast.error("User role don't have permissions!");
        default:
          break;
      }
      return Promise.reject(error);
    }
);
