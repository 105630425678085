export default {
  language: {
    vi: "Vietnamese",
    en: "English",
  },
  button: {
    login: "Đăng nhập",
    logout: "Đăng xuất",
    submit: "Gửi",
  },
  translatetype: {
    text: "Văn bản",
    document: "Tài liệu",
    image: "Hình ảnh",
  },
  languagepanel: {
    nomscript: "HÁN - NÔM",
    vietnamese: "QUỐC NGỮ",
  },
  actions: {
    text: "Tải về dạng TEXT",
    pdf: "Tải về dạng PDF",
    review: "Thêm nhận xét",
    edit: "Hiệu chỉnh bản dịch",
    uploadImage: "Tải ảnh văn bản thông thường",
    verticalUploadImage: "Tải ảnh dịch theo chiều dọc",
    senceUploadImage: "Tải ảnh ngoại cảnh Hán tự",
    documentUploadImage: "Tải ảnh văn bản hành chính"
  },
  extraactions: {
    feedback: "Gửi phản hồi",
    history: "Lịch sử",
    saved: "Đã lưu",
    review: "Nhận xét",
    favorite: "Lưu trữ",
    nomKeyboard: "Hướng dẫn bộ gõ"
  },
  exception: {
    title: "Thông báo",
    exceptionbody: "Bạn cần đăng nhập vào tài khoản Google",
    successbody: "Thành công!!!",
    button: "Đồng ý",
  },
  footer:
    "Công trình này được thực hiện bởi một số giảng viên <a href='https://www.fit.hcmus.edu.vn' target='_blank'>Khoa&nbsp;Công&nbsp;nghệ&nbsp;Thông&nbsp;tin</a> và&nbsp;<a target='_blank' href='https://www.clc.hcmus.edu.vn/'>Trung&nbsp;tâm&nbsp;Ngôn&nbsp;ngữ&nbsp;học&nbsp;Tính&nbsp;toán</a> thuộc&nbsp;<a target='_blank' href='https://www.hcmus.edu.vn/'>Trường&nbsp;ĐH&nbsp;Khoa&nbsp;học&nbsp;Tự&nbsp;nhiên&nbsp;-&nbsp;ĐHQG&nbsp;-&nbsp;HCM</b></a> trong khuôn khổ đề tài nghiên cứu khoa học của&nbsp;<a target='_blank' href='https://dost.hochiminhcity.gov.vn'/>Sở&nbsp;Khoa&nbsp;học&nbsp;&&nbsp;Công&nbsp;nghệ&nbsp;TpHCM</a>",
  header: {
    title: "CLC Phiên dịch Hán Nôm",
  },
  tooltip: {
    copy: "Sao chép",
    edit: " Chỉnh sửa",
    download:"Tải xuống",
    review: "Đánh giá",
    save:"Lưu",
    unsave:"Bỏ lưu",
    keyboard:"Tắt bộ gõ chữ Nôm",
    nomKeyboard:"Bật bộ gõ chữ Nôm",
    clear:"Xóa",
    fit: "Khoa Công nghệ Thông tin",
    clc: "Trung tâm Ngôn ngữ học Tính toán"
  },
  verticalOCRStep: {
    step1: "Bước 1: Tải ảnh lên",
    step2: "Bước 2: Thay đổi kích thước ảnh cho phù hợp và chọn nút 'Cắt ảnh' (có thể cắt ảnh thành nhiều phần khác nhau)",
    step3: "Bước 3: Bắt đầu nhận dạng ảnh",
    crop: "Cắt ảnh",
    upload: "Tải ảnh lên",
    croppedImages: "Ảnh đã cắt",
    submit: "Gửi",
  },
  interpretation: {
    interpretation: "Dịch nghĩa (thử nghiệm)",
    transcription: "Phiên âm",
    toggle: "Dịch nghĩa"
  }
};

