<template>
  <v-container style="calc(100vh - 65px)">
    <v-textarea counter outlined hide-details></v-textarea>
  </v-container>
</template>

<style>
</style>

<script>
export default {};
</script>