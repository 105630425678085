<template>
  <v-container style="calc(100vh - 65px)">
    <v-list>
      <v-list-item-group color="primary">
        <template v-for="(item, i) in reviewList">
          <v-list-item :key="item.nom + item.vie">
            <template>
              <v-list-item-content>
                <SentencePair :sourceText="item.nom" :targetText="item.vie" />

                <div class="mt-3 subtitle-2">{{ $t("extraactions.review") }}:</div>
                <v-list-item-subtitle
                  color="#5f6368"
                  style="white-space: pre-line"
                  v-text="item.review"
                >
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-divider v-if="i <= reviewList.length - 1" :key="i"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<style>
</style>

<script>
import SentencePair from "@src/components/extrapane/sentencepair/SentencePair.vue";

export default {
  name: "ReviewHistory",
  props: {
    reviewList: Array,
  },
  components: {
    SentencePair,
  },
};
</script>