import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import GAuth from "vue-google-oauth2";
import apiPlugin from "./api";
import i18n from "./i18n";
import store from "./store";
import "./config/interceptors-axios";
import router from "./router/index"; // Import Vue Router instance

const gauthOption = {
  clientId: process.env.VUE_APP_CLIENT_ID,
  //clientId:
  //  "395093615835-8jenhi6ht132nt3b7m27vp5p7ea07u5m.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
  plugin_name: "fix-lib",
};

Vue.use(GAuth, gauthOption);
Vue.use(apiPlugin);
Vue.use(router);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
