<template>
  <v-container>
    <v-toolbar flat dense height="20">
      <v-row justify="space-between">
        <v-col cols="5">
          <v-tabs>
            <v-tab>
              {{
                this.$props.translationDirection === "nom-qn" || this.$props.translationDirection === "han-qn" 
                  ? $t("languagepanel.nomscript")
                  : $t("languagepanel.vietnamese")
              }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="1" class="d-flex justify-center">
          <v-btn icon color="grey" @click="toggleDirection">
            <v-icon>mdi-repeat</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="5">
          <v-tabs>
            <v-tab>
              {{
                this.$props.translationDirection === "nom-qn" || this.$props.translationDirection === "han-qn" 
                  ? $t("languagepanel.vietnamese")
                  : $t("languagepanel.nomscript")
              }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-toolbar>
  </v-container>
</template>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.v-tab,
.v-tabs-slider-wrapper {
  width: 100%;
}
</style>

<script>
export default {
  name: "LanguagePanel",
  data: () => ({
    languagePair: {
      source: "languagepanel.nomscript",
      target: "languagepanel.vietnamese",
    },
  }),
  props: {
    translationDirection: String,
  },
  methods: {
    toggleDirection() {
      this.$emit("updateTranslationDirection", "toggle");
    },
  },
};
</script>
