const state = {
  idtoken: null,
  emailStr: '',
  usernameStr: '',
  imageUrl: '',
  fileUpload: false,
};

const getters = {
  isauthen: state => {
    return !!state.idtoken;
  },
  gettoken: state => {
    return state.idtoken
  },
  email: state => {
    return state.emailStr
  },
  username: state => {
    return state.usernameStr
  },
  image: state => {
    return state.imageUrl
  },
  upload: state => {
    return state.fileUpload
  }
};

const mutations = {
  // Email
  email(state, newemail) {
    state.emailStr = newemail
  },
  removeemail(state) {
    state.emailStr = ''
  },
  // Username
  username(state, newusername) {
    state.usernameStr = newusername
  },
  removeusername(state) {
    state.usernameStr = ''
  },
  // Avatar
  image(state, newimage) {
    state.imageUrl = newimage
  },
  removeimage(state) {
    state.imageUrl = ''
  },
  logout(state) {
    state.idtoken = null;
  },
  login(state, newtoken) {
    if (newtoken) {
      state.idtoken = newtoken;
    } else {
      state.idtoken = null;
    }
  },
  file(state) {
    state.fileUpload = true;
  },
  unfile(state) {
    state.fileUpload = false;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};