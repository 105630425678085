<template>
  <v-dialog v-model="isOpen" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"> {{ $t("exception.title") }} </v-card-title>
      <v-card-text class="subtitle-1">
        {{ $t("exception.exceptionbody") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"> {{ $t("exception.button") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
</style>

<script>
export default {
  name: "ExceptionDialog",
  data: () => ({}),
  props: {
    dialog: Boolean,
  },
  computed: {
    isOpen() {
      return this.dialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false)
    },
  }
};
</script>