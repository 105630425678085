<template>
    <div color="white" class="header" dense>
        <div class="header" @click.stop="toggleDrawer">
            <div class="nav-icon">&#x2630;</div>
        </div>
        <NavigationDrawer :drawer="drawer" @closeDrawer="closeDrawer" />

        <div class="bar" @click.stop="toggleDrawer">
            <router-link to="/" class="title">
                <img src="../assets/nom-logo.jpg" alt="Logo" class="logo1" />
                <h1>{{ $t("header.title") }}</h1>
            </router-link>

            <div @click.stop="toggleDrawer">
                <a href="https://www.fit.hcmus.edu.vn/" target="_blank">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <img src="../assets/fit.jpg" alt="Logo" class="logo2" v-on="on" />
                        </template>
                        <span>{{ $t("tooltip.fit") }}</span>
                    </v-tooltip>
                </a>
                <a href="https://www.clc.hcmus.edu.vn/" target="_blank">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <img src="../assets/clc.png" alt="Logo" class="logo2" v-on="on" />
                        </template>
                        <span>{{ $t("tooltip.clc") }}</span>
                    </v-tooltip>
                </a>
            </div>
        </div>
    </div>
</template>
  
<script>
import NavigationDrawer from "@src/views/NavigationDrawer.vue";

export default {
    name: "Header",
    components: {
        NavigationDrawer,
    },
    data: () => ({
        drawer: false,
    }),
    methods: {
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
        closeDrawer() {
            this.drawer = true;
        },
    },
};
</script>
  
<style scoped>
/* Add any specific CSS styles for the Header component here */
.header {
    background-color: white;
}

.bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5% 0 5%;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo1 {
    height: 80px;
}

.logo2 {
    height: 60px;
}

.nav-icon {
  font-size: 34px; 
}


h1 {
    border-left: 2px solid #f5f5f5;
    margin-left: 10px;
    padding-left: 10px;
    font-size: 30px;
    text-decoration: none;
    color: #222;

    /* color: #1976d2; */
}

@media (max-width: 1024px) {
    .logo1 {
        height: 60px;
    }

    .logo2 {
        height: 40px;
    }

    h1 {
        font-size: 24px;
        border: none;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .bar {
        align-items: flex-start;
    }

    .logo1 {
        height: 80px;
    }

    .logo2 {
        height: 30px;
    }

    h1 {
        font-size: 20px;
        white-space: nowrap;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

a {
    text-decoration: none;
    color: #222 !important;
    font-weight: 450;
}
</style>
  