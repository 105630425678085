<template>
  <v-card flat>
    <div class="tools">
      <TranslateType
        @exceptionPopup="exceptionPopup"
        @successPopup="successPopup"
        :translationType="translationType"
        @changeTranslationType="changeTranslationType"
      />
      <ExtraActions
        @historyEmit="historyEmitAgain"
        @documentEmit="documentEmitAgain"
        @reviewEmit="reviewEmitAgain"
        @favoriteEmit="favoriteAgain"
        @exceptionPopup="exceptionPopup"
      />
    </div>

    <v-card class="mx-auto rounded-lg" width="90%" style="margin-top: 15px">
      <LanguagePanel
        @updateTranslationDirection="updateTranslationDirection"
        :translationDirection="translationDirection"
      />

      <v-divider></v-divider>

      <ContentInput
        :translationType="translationType"
        @editPopup="editPopup"
        @reviewPopup="reviewPopup"
        @exceptionPopup="exceptionPopup"
        @changeTranslationType="changeTranslationType"
        :translationDirection="translationDirection"
        @updateTranslationDirection="updateTranslationDirection"
      />
    </v-card>
    <!-- <v-card class="mx-auto rounded-lg" width="90%" style="margin-top: 15px" flat>
      <v-btn outlined color="blue darken-2" @click="toggleTable">
        {{ $t("interpretation.toggle") }}
      </v-btn>
    </v-card> -->
  </v-card>
</template>

<script>
import ExtraActions from "@src/components/ExtraActions.vue";
import TranslateType from "@src/components/TranslateType.vue";
import LanguagePanel from "@src/components/translation/LanguagePanel.vue";
import ContentInput from "@src/components/translation/ContentInput.vue";

export default {
  name: "Translation",
  components: {
    ExtraActions,
    TranslateType,
    LanguagePanel,
    ContentInput,
  },
  data: () => ({
    translationType: "text",
    translationDirection: "nom-qn",
    showTable: false,
  }),
  methods: {
    toggleTable() {
      this.showTable = !this.showTable;
    },
    updateTranslationDirection(val) {
      if (val === "han-qn") {
        this.translationDirection = "han-qn";
      } else if (val === "toggle") {
        if (this.translationDirection === "nom-qn") {
          this.translationDirection = "qn-nom";
        } else {
          this.translationDirection = "nom-qn";
        }
      } else {
        this.translationDirection = "nom-qn";
      }
    },
    changeTranslationType(val) {
      this.translationType = val;
    },
    historyEmitAgain(history) {
      this.$emit("historyEmitAgain", history);
    },
    documentEmitAgain(val) {
      this.$emit("documentEmitAgain", val);
    },
    reviewEmitAgain(review) {
      this.$emit("reviewEmitAgain", review);
    },
    favoriteAgain(favorite) {
      this.$emit("favoriteEmitAgain", favorite);
    },
    editPopup({ sourceText, targetText }) {
      this.$emit("editPopupAgain", {
        sourceText: sourceText,
        targetText: targetText,
      });
    },
    reviewPopup({ sourceText, targetText }) {
      this.$emit("reviewPopupAgain", {
        sourceText: sourceText,
        targetText: targetText,
      });
    },
    exceptionPopup(val) {
      if (val) {
        this.$emit("exceptionPopup", true);
      }
    },
    successPopup(val) {
      if (val) {
        this.$emit("successPopup", true);
      }
    },
  },
};
</script>

<style>
.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px 5%;
}

@media (max-width: 1091px) {
  .tools {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
}
</style>
