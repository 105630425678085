<template>
    <div class="footer" @click.stop="toggleDrawer">
        <a href="https://www.hcmus.edu.vn/" target="_blank">
            <img src="../assets/hcmus.png" class="logo3" />
        </a>
        <p v-html="$t('footer')" />
        <a href="https://dost.hochiminhcity.gov.vn/" target="_blank">
            <img src="../assets/so-khcn.jpg" class="logo3" />
        </a>
    </div>
</template>
  
<script>
export default {
    name: "Footer",
    methods: {
        toggleDrawer() {
            // Add any specific functionality related to toggling the drawer in the Footer component
        },
    },
};
</script>
  
<style scoped>
/* Add any specific CSS styles for the Footer component here */
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #222;
    font-size: 16px;
    padding: 20px 15%;
    background-color: white;
}

.logo3 {
    height: 80px;
}

p {
    margin: 0 !important;
}

@media (max-width: 1024px) {

    .logo3 {
        height: 40px;
    }

}

@media (max-width: 768px) {

    .footer {
        flex-direction: column;
        font-size: 12px;
        position: relative;
    }

    .logo3 {
        height: 30px;
    }
}

a {
    text-decoration: none;
    color: #222 !important;
    font-weight: 450;
}

p {
    margin: 0 !important;
}
</style>
  