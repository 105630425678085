<template>
  <v-container style="calc(100vh - 65px)">
    <SentencePair :sourceText="sourceText" :targetText="targetText" />

    <v-textarea class="mt-4" counter outlined hide-details v-model="review"></v-textarea>

    <v-btn text color="blue darken-2" class="mt-4" @click="submit">
      {{ $t("button.submit") }}
    </v-btn>
  </v-container>
</template>

<style>
</style>

<script>
import SentencePair from "@src/components/extrapane/sentencepair/SentencePair.vue";

export default {
  name: "ReviewSubmit",
  data: () => ({
    review: "",
  }),
  props: {
    sourceText: String,
    targetText: String,
  },
  components: {
    SentencePair,
  },
  methods: {
    async submit() {
      let res = await this.$api.translation.submitReview({
        nom: this.sourceText,
        vie: this.targetText,
        review: this.review,
      });

      if (res.status === 200 && res.data && res.data["success"]) {
        this.$emit("successPopup", true);
      } else {
        if (typeof res.data["err"] != "undefined") {
          if (res.data["err"].split(",")[0].trim() == "Token expired") {
            this.$store.commit("removeemail");
            this.$store.commit("removeusername");
            this.$store.commit("removeimage");

            this.$store.commit("logout");
            this.$emit("exceptionPopup", true);
          } else {
            window.console.error(res.data["err"]);
          }
        }
      }
    },
  },
};
</script>