<template>
  <v-card
    tile
    outlined
    flat
    height="fit-content"
    max-height="100vh"
    width="100%"
    elevation="2"
    class="overflow-hidden"
  >
    <v-app-bar
      flat
      elevate-on-scroll
      color="white"
      scroll-target="#scrolling-body"
    >
      <v-toolbar-title class="ml-4">{{ popupTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="closeEmit">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-divider></v-divider>

    <v-sheet id="scrolling-body" class="overflow-y-auto" max-height="600">
      <Modification
        v-if="typePane == 1"
        :sourceText="sourceText"
        :targetText="targetText"
        @successPopup="successPopup"
      />
      <ReviewSubmit
        v-else-if="typePane == 2"
        :sourceText="sourceText"
        :targetText="targetText"
        @successPopup="successPopup"
      />
      <ReviewHistory v-else-if="typePane == 3" :reviewList="reviewList" />
      <History v-else-if="typePane == 4" :historyList="historyList" />
      <Document v-else-if="typePane == 5" />
      <Favorite v-else-if="typePane == 6" :favoriteList="favoriteList" />
    </v-sheet>
  </v-card>
</template>

<style>
</style>

<script>
import Modification from "@src/components/extrapane/Modification.vue";
import ReviewSubmit from "@src/components/extrapane/ReviewSubmit.vue";
import ReviewHistory from "@src/components/extrapane/ReviewHistory.vue";
import History from "@src/components/extrapane/History.vue";
import Document from "@src/components/extrapane/Document.vue";
import Favorite from "@src/components/extrapane/Favorite.vue";

export default {
  name: "ExtraPane",
  data: () => ({}),
  props: {
    popupTitle: String,
    typePane: Number,
    sourceText: String,
    targetText: String,
    reviewList: Array,
    historyList: Array,
    favoriteList: Array,
  },
  components: {
    Modification,
    ReviewSubmit,
    ReviewHistory,
    History,
    Document,
    Favorite,
  },
  methods: {
    closeEmit() {
      this.$emit("closeEmitted", true);
    },
    successPopup(val) {
      if (val) {
        this.$emit("successPopup", true);
      }
    },
  },
};
</script>