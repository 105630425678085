<template>
  <v-dialog @click:outside="closeDialog" :value="ocrDialog">
    <div :onload="checkBodyOnLoad()" style="background: #fff; min-height: 100vh" ref="OCRDialog">
      <nav class="navbar sticky-top navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand d-flex align-items-center" href="#">
            <v-btn icon dark large @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </a>
        </div>
      </nav>

      <main class="container" style="margin-top: 30px;">
        <!-- Pre-OCR Form -->
        <form id="form-pre-ocr">
          <div class="form-group">
            <div class="row row-spacing">
              <div class="col-sm-3">
                <h2>Step 1</h2>
                <p>
                  Upload an image.
                </p>
                <input
                  type="file"
                  name="inputImageFile"
                  class="form-control"
                  id="input-imageFile"
                  value="null"
                />
              </div>

              <div class="col-sm-1"><hr /></div>

              <div class="col-sm-3">
                <h2>Step 2</h2>
                <p>
                  Select image type.
                </p>
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle"
                    id="dropdownMenu1"
                    data-bs-toggle="dropdown"
                    data-bs-target="dropdownMenu1"
                    aria-haspopup="true"
                    aria-expanded="true"
                    @click="dropdownMenuDomain"
                  >
                    Select a domain
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" 
                      aria-labelledby="dropdownMenu1"
                      >
                    <li>
                      <a class="dropdown-item" 
                         href="#" 
                         data-value="Historical documents"
                        >Historical documents</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item disabled"
                        href="#"
                        data-value="Modern documents"
                        >Modern documents</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item disabled"
                        href="#"
                        data-value="Mixed with other scripts"
                        >Mixed with other scripts</a
                      >
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                  </ul>
                </div>
                <input
                  type="hidden"
                  name="inputContentDomain"
                  id="input-contentDomain"
                  value="null"
                />
              </div>

              <div class="col-sm-1"><hr /></div>

              <div class="col-sm-3">
                <h2>Step 3</h2>
                <p>
                  Start processing.
                </p>
                <button 
                  id="button-run-ocr" 
                  type="button"
                  class="btn btn-primary" 
                  @click="submitOCRForm" 
                >
                Run OCR
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- Pre-OCR Form -->

        <!-- Progress Bar -->
        <div class="row row-spacing" id="divProgress" style="display: none;">
          <h2>Processing</h2>
          <div class="col-sm-2">
            <p>Performing OCR</p>
          </div>
          <div class="col-sm-10">
            <div class="progress" style="height: 20px;">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style="width: 100%;"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                id="progressTimer"
              >
                0s
              </div>
            </div>
          </div>
        </div>
        <!-- Progress Bar -->

        <!-- Result -->
        <div class="row row-spacing" id="divResult" style="display: none;">
          <h2>Result</h2>

          <div class="col-md-5" style="display: none;">
            <div
              class="card border-info sm-12 sticky-top"
              style="max-height: 680px; top: 120px;"
            >
              <div class="card-header">Detections</div>
              <div
                class="card-text overflow"
                style="overflow-x: scroll; overflow-y: scroll;"
              >
                <img
                  src="/"
                  class="card-text"
                  style="background-size: 100% auto;"
                  alt="OCR Result"
                  id="image-OCR-result"
                  usemap="#map-bbox"
                />
                <map name="map-bbox" id="map-bbox"></map>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="card border-info sm-12" style="height: 480px; display: none;">
              <div class="card-header">Focus on each character</div>
              <div class="col-sm-12 p-3" id="table-header">
                <div
                  class="card border-warn-m sm-12 p-3"
                  style="margin-right: 16px;"
                >
                  <div class="row" style="color: #0D6EFD;">
                    <h6 class="col-sm-2 card-text">Crop Image</h6>
                    <h6 class="col-sm-2 card-text">Top 1</h6>
                    <h6 class="col-sm-2 card-text">Top 2</h6>
                    <h6 class="col-sm-2 card-text">Top 3</h6>
                    <h6 class="col-sm-2 card-text">Top 4</h6>
                    <h6 class="col-sm-2 card-text">Top 5</h6>
                  </div>
                </div>
              </div>
              <div
                class="card-text p-3 overflow-auto"
                style="position: relative; overflow-y: scroll;"
                id="bbox_list_scroller"
              >
                <div
                  class="col-sm-12"
                  id="bbox-list-holder"
                  style="position: relative;"
                >
                  <div
                    class="card border-info-m sm-12 p-3"
                    id="bbox-item-root"
                    style="display: none;"
                  >
                    <div class="row d-flex align-items-center">
                      <div class="col-sm-2" style="align-items: center;">
                        <div
                          class="card-text"
                          style="width: 64px; height: 64px;"
                          id="bbox-item-root-img"
                        ></div>
                        <p
                          class="card-text"
                          id="bbox-item-root-bboxnum"
                          style="font-size: small;"
                        >
                          BBox 0
                        </p>
                      </div>
                      <div class="col-sm-2">
                        <h2
                          class="card-text focuschar"
                          id="bbox-item-root-char1"
                          style="font-family: 'Nom Na Tong';"
                        >
                          𤾓
                        </h2>
                        <p class="card-text" id="bbox-item-root-ucode-ocrconf1">
                          24F93<br />0.98
                        </p>
                        <div
                          class="row card-text"
                          style="height: 24px; display: none;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char1_top1"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char1_top2"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char1_top3"
                            value=""
                            aria-label="..."
                          />
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <h2
                          class="card-text focuschar"
                          id="bbox-item-root-char2"
                          style="font-family: 'Nom Na Tong';"
                        >
                          𤾓
                        </h2>
                        <p class="card-text" id="bbox-item-root-ucode-ocrconf2">
                          24F93<br />0.98
                        </p>
                        <div
                          class="row card-text"
                          style="height: 24px; display: none;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char2_top1"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char2_top2"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char2_top3"
                            value=""
                            aria-label="..."
                          />
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <h2
                          class="card-text focuschar"
                          id="bbox-item-root-char3"
                          style="font-family: 'Nom Na Tong';"
                        >
                          𤾓
                        </h2>
                        <p class="card-text" id="bbox-item-root-ucode-ocrconf3">
                          24F93<br />0.98
                        </p>
                        <div
                          class="row card-text"
                          style="height: 24px; display: none;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char3_top1"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char3_top2"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char3_top3"
                            value=""
                            aria-label="..."
                          />
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <h2
                          class="card-text focuschar"
                          id="bbox-item-root-char4"
                          style="font-family: 'Nom Na Tong';"
                        >
                          𤾓
                        </h2>
                        <p class="card-text" id="bbox-item-root-ucode-ocrconf4">
                          24F93<br />0.98
                        </p>
                        <div
                          class="row card-text"
                          style="height: 24px; display: none;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char4_top1"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char4_top2"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char4_top3"
                            value=""
                            aria-label="..."
                          />
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <h2
                          class="card-text focuschar"
                          id="bbox-item-root-char5"
                          style="font-family: 'Nom Na Tong';"
                        >
                          𤾓
                        </h2>
                        <p class="card-text" id="bbox-item-root-ucode-ocrconf5">
                          24F93<br />0.98
                        </p>
                        <div
                          class="row card-text"
                          style="height: 24px; display: none;"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char5_top1"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char5_top2"
                            value=""
                            aria-label="..."
                          />
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onchange="this.checked=!this.checked"
                            autocomplete="off"
                            id="checkbox_lm_root_char5_top3"
                            value=""
                            aria-label="..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p
                class="card-text"
                style="text-align: right; font-size: 12px;font-style: italic; margin-right: 32px; height: 24px; margin-top: 6px; margin-bottom: 6px; display: none;"
              >
                Legends:
                <input
                  class="form-check-input"
                  type="checkbox"
                  onchange="this.checked=!this.checked"
                  autocomplete="off"
                  id="checkbox_lm_legend_top1"
                  value=""
                  aria-label="..."
                  checked
                  style="background-color: #de2f40;"
                />
                LM beam top 1;
                <input
                  class="form-check-input"
                  type="checkbox"
                  onchange="this.checked=!this.checked"
                  autocomplete="off"
                  id="checkbox_lm_legend_top2"
                  value=""
                  aria-label="..."
                  checked
                  style="background-color: #fd7e14"
                />
                LM beam top 2;
                <input
                  class="form-check-input"
                  type="checkbox"
                  onchange="this.checked=!this.checked"
                  autocomplete="off"
                  id="checkbox_lm_legend_top3"
                  value=""
                  aria-label="..."
                  checked
                  style="background-color: #ffc107"
                />
                LM beam top 3
              </p>
            </div>
            <div
              class="card border-info sm-12 row-spacing"
              style="height: 250px;"
            >
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-8 d-flex align-items-center">
                    <p class="card-text" id="text-FinalTextOCR">Text Output</p>
                  </div>
                </div>
              </div>

              <ul class="nav nav-pills p-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-nom-tab" data-bs-toggle="pill" data-bs-target="#pills-nom" type="button" role="tab" aria-controls="pills-nom" aria-selected="true">Nôm text</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-quoc-ngu-tab" data-bs-toggle="pill" data-bs-target="#pills-quoc-ngu" type="button" role="tab" aria-controls="pills-quoc-ngu" aria-selected="false">Quốc ngữ text</button>
                </li>
              </ul>
              <div class="tab-content card-text p-3 overflow-auto" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-nom" role="tabpanel" aria-labelledby="pills-nom-tab">
                  <p
                    class="card-text"
                    id="final-text-OCR-Nom"
                    style="font-family: 'Nom Na Tong';"
                  >
                    None
                    <!-- 𤾓 𢆥 𥪞 𡎝 𠊛 些<br />
                    𡨸 才 𡨸 命 窖 𦉼 恄 饒<br />
                    𣥱 戈 没 局 𣷭 橷<br />
                    仍 調 𬖉 𧡊 㐌 𤴬 疸 𢚸<br />
                    𨔍 之 彼 嗇 斯 豐<br />
                    𡗶 撑 涓 貝 𦟐 紅 打 悭<br />
                    稿 𦹳 吝 𢷣 𠓀 畑<br />
                    風 情 固 錄 群 傳 史 撑<br />
                    浪 𢆥 嘉 靖 朝 明<br />
                    𦊚 方 𪹚 𣼽 𠄩 京 凭 鐄<br />
                    固 茄 員 外 户 王<br /> -->
                  </p>
                </div>
                <div class="tab-pane fade" id="pills-quoc-ngu" role="tabpanel" aria-labelledby="pills-quoc-ngu-tab">
                  <p
                    class="card-text"
                    id="final-text-OCR-QN"
                  >
                    None
                    <!-- Trăm năm trong cõi người ta<br />
                    Chữ tài chữ mệnh khéo là ghét nhau<br />
                    Trải qua một cuộc bể dâu<br />
                    Những điều trông thấy đã đau đớn lòng<br />
                    Lạ gì bỉ sắc tư phong<br />
                    Trời xanh quen với má hồng đánh ghen<br />
                    Cảo thơm lần giở trước đèn<br />
                    Phong tình có lục còn truyền sử xanh<br />
                    Rằng Năm Gia Tĩnh triều Minh<br />
                    Bốn phương phẳng lặng, hai kinh vững vàng<br />
                    Có nhà viên ngoại họ Vương<br /> -->
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button 
            id="button-run-ocr" 
            type="button"
            class="btn btn-primary" 
            @click="copyOCRTextToClipboardAndClose" 
          >
          Copy OCR Text
          </button>
        </div>
        <!-- Result -->
      </main>
    </div>
    
  </v-dialog>
</template>

<style scoped src='../assets/bootstrap.min.css'>
</style>

<script>
import {checkBodyOnLoad, _dropdownMenuDomain, _submitOCRForm, _closeDialog, _copyOCRTextToClipboardAndClose} from '../../public/static/js/ocr'

export default {
  name: "UploadImage",
  data: () => ({
    chosenFile: [],
    viShow: [],
    viTrans: [],
  }),
  props: {
    ocrDialog: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeDialog() {
      _closeDialog(this);
    },
    async nomTransFunc(val) {
      if (val.length > 0) {
        let res = await this.$api.translation.nomtranslation({
          nom_text: val,
        });

        if (res.status === 200 && res.data) {
          this.viTrans = res.data["sentences"];

          this.viShow = []; // Remove old content
          this.viTrans.forEach((ele) => {
            this.viShow.push(ele[0]);
          });
        } else {
          if (typeof res.data["err"] != "undefined") {
            if (res.data["err"].split(",")[0].trim() == "Token expired") {
              this.$store.commit("removeemail");
              this.$store.commit("removeusername");
              this.$store.commit("removeimage");

              this.$store.commit("logout");
              this.$emit("exceptionPopup", true);
            } else {
              window.console.error(res.data["err"]);
            }
          }
        }
      }
    },
    checkBodyOnLoad,
    dropdownMenuDomain() {
      _dropdownMenuDomain(this.$refs['OCRDialog']);
    },
    submitOCRForm() {
      _submitOCRForm(this.$refs['OCRDialog']);
    },
    copyOCRTextToClipboardAndClose() {
      _copyOCRTextToClipboardAndClose(this);
    }
  },
};
</script>
