<template>
  <v-app>
    <Header />
    <v-main>
      <div class="about-us">
        <h1 class="center">Về chúng tôi</h1>
        <div>
          <p>
            Về công trình của chúng tôi: Dân tộc Việt Nam trong những thế kỷ
            trước đã sử dụng chữ Hán và chữ Nôm như một hệ chữ viết chính để ghi
            chép trong đời sống văn hoá ở khắp các lĩnh vực văn học, hành chính,
            y học, sử học,…và một phần các tài liệu này vẫn còn được lưu trữ
            đến ngày nay. Vì vậy, đây là những di sản văn hoá mang giá trị tinh
            thần và ý nghĩa to lớn về cả mặt khoa học và thực tiễn nên cần được
            bảo tồn, khai thác và phát huy giá trị. Trong đó về mặt thực tiễn, 
            các văn bản Hán Nôm ngoại cảnh ở các khu di tích, đền chùa,… 
            còn góp phần phát triển ngành du lịch ở Việt Nam. 
          </p>
          <p>
            Trong bối cảnh ngày nay công nghệ thông tin đang phát triển
            mạnh mẽ và thực trạng rằng số lượng người Việt trẻ biết chữ Hán cổ
            và chữ Nôm không còn nhiều, thiết nghĩ chúng ta nên có những công cụ
            tự động hỗ trợ việc dịch thuật Hán Nôm. Dựa trên động lực này, chúng
            tôi đã ứng dụng trí tuệ nhân tạo cùng với các tri thức ở các ngành
            khoa học khác như ngôn ngữ học, Hán – Nôm, văn học,… để xây dựng nên
            website này nhằm góp phần kết nối tri thức từ quá khứ đến hiện tại.
            Công trình này được thực hiện bởi một số giảng viên Khoa Công nghệ
            Thông tin và Trung tâm Ngôn ngữ học Tính toán thuộc Trường ĐH Khoa
            học Tự nhiên - ĐHQG - HCM trong khuôn khổ đề tài nghiên cứu khoa học
            của Sở Khoa học & Công nghệ TpHCM. Website gồm 2 nhóm chức năng
            chính là nhận dạng và dịch văn bản Hán Nôm. Trong đó bao gồm
            <b>các chức năng chi tiết </b> như sau:
          </p>
          <p>
          <ul class="custom-list">
            <li>1. Chuyển tự chữ Hán và chữ Nôm sang chữ Quốc ngữ.</li>
            <li>
              2. Dịch nghĩa văn bản chữ Hán (đang trong giai đoạn thử nghiệm).
            </li>
            <li>3. Nhận dạng và dịch văn bản Hán Nôm từ hình ảnh.</li>
            <li>
              4. Nhận dạng và dịch văn bản Hán Nôm từ hình ảnh ở lĩnh vực hành
              chính (sắc phong, chế phong, chiếu chỉ,…).
            </li>
            <li>
              5. Nhận dạng và dịch văn bản chữ Hán ngoại cảnh (đền chùa, miếu
              mạo, khu di tích,…).
            </li>
          </ul>
          </p>
          <p>
            Trong các tính năng trên, tính năng chuyển tự chữ Nôm sang chữ Quốc
            ngữ đã đạt được chất lượng khá tốt với độ chính xác hơn 80%. Còn các
            chức năng khác chúng tôi đang trong tiến trình phát triển và hoàn
            thiện, đặc biệt là tính năng dịch nghĩa vì đây là một đề tài nghiên
            cứu vô cùng thử thách. Ngoài ra, chúng tôi cũng đã công bố nhiều bài
            báo khoa học trong nước và quốc tế liên quan đến các tính năng này.
            Kính mời quý vị sử dụng và cho góp ý. Chúng tôi xin lắng nghe và ghi
            nhận để ngày càng hoàn thiện công trình này hơn với mục tiêu hỗ trợ
            thế hệ người Việt hiện đại trong sứ mệnh tìm về các giá trị cũ thông
            qua thư tịch Hán Nôm.

            
          </p>
          <strong>Trân trọng,</strong>
          <br>
          <strong>CLC</strong>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<style scoped>
.about-us {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.about-us h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.about-us div p {
  margin-bottom: 20px;
  line-height: 1.5;
}
.custom-list {
  list-style-type: disc;
  padding-left: 20px;
  color: #0b60b0; /* Màu chữ trong thẻ ul */
  font-size: large;
  font-weight: bold;
}
.custom-list li {
  margin-bottom: 10px;
  line-height: 1.3;
}
.center {
  text-align: center;
}

</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "AboutUs",
  components: {
    Header,
    Footer,
  },
};
</script>
