
<template>
  <v-app>
    <Header />
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="languageOptions"
              label="Chọn ngôn ngữ / Choose Language"
              @change="handleChangeLanguage"
            ></v-select>
          </v-col>
        </v-row>
        <div v-html="html_body">
        </div>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

var htmlVi = `<h2 style="text-align: center;"><strong> Hướng dẫn sử dụng <br />Trang Web CLC &ndash; Phi&ecirc;n dịch H&aacute;n N&ocirc;m</strong></h2>
<h3><strong>Nội dung</strong></h3>
<table style="border-collapse: collapse; width: 99.7287%; height: 207px;" border="0">
<tbody>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px;"><strong><a href="#tag1">1. Dịch văn bản H&aacute;n N&ocirc;m&nbsp; &hArr; Quốc ngữ</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px;"><strong><a href="#tag2">2. Nhận dạng v&agrave; dịch văn bản H&aacute;n N&ocirc;m dạng h&igrave;nh ảnh</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px;"><strong><a  href="#tag3">3. Một số t&iacute;nh năng kh&aacute;c</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px; padding-left: 40px;"><strong><a  href="#tag31">3.1 Xem lại lịch sử dịch</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px; padding-left: 40px;"><strong><a  href="#tag32">3.2 Chức năng lưu văn bản đ&atilde; dịch</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px; padding-left: 40px;"><strong><a  href="#tag33">3.3 Chức năng chuyển đổi ng&ocirc;n ngữ của giao diện</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px; padding-left: 40px;"><strong><a  href="#tag34">3.4 Chức năng sao ch&eacute;p bản dịch</a></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 750px; height: 18px; padding-left: 40px;"><strong><a  href="#tag35">3.5 Chức năng tải bản dịch</a></strong></td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<h3 id="tag1">1. Dịch văn bản H&aacute;n N&ocirc;m &hArr; Quốc ngữ</h3>
<p><strong>- Bước 1</strong>: V&agrave;o website https://tools.clc.hcmus.edu.vn/</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image01.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 1</strong>. Trạng th&aacute;i ban đầu của website</p>
<p><strong>- Bước 2:</strong> Chọn mục văn bản (đ&acirc;y cũng l&agrave; lựa chọn mặc định khi truy cập v&agrave;o web)</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image02.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 2</strong>. Lựa chọn chức năng dịch văn bản</p>
<p><strong>- Bước 3:</strong> Chọn chiều dịch</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image03.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 3</strong>. Lựa chọn chiều dịch (H&aacute;n N&ocirc;m &hArr; Quốc ngữ)</p>
<p>Website hiện tại hỗ trợ dịch 2 chiều giữa văn tự H&aacute;n N&ocirc;m v&agrave; Quốc Ngữ. Ch&uacute;ng ta c&oacute; thể chuyển đổi bằng c&aacute;ch nhấp v&agrave;o mũi t&ecirc;n 2 chiều ở giữa. &Ocirc; b&ecirc;n tr&aacute;i sẽ l&agrave; nơi nhập văn bản muốn dịch, &ocirc; b&ecirc;n phải sẽ l&agrave; kết quả dịch.</p>
<p><strong>- Bước 4:</strong> Nhập văn bản cần dịch ở &ocirc; b&ecirc;n tr&aacute;i v&agrave; nhận kết quả dịch ở &ocirc; b&ecirc;n phải.</p>
<p style="padding-left: 40px;">Với văn bản nguồn l&agrave; chữ N&ocirc;m th&igrave; c&ocirc;ng cụ sẽ chỉ chuyển tự sang chữ Quốc ngữ. (H&igrave;nh 4)</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image04.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 4</strong>. V&iacute; dụ 1: Dịch văn bản chữ N&ocirc;m</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image05.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 5</strong>. V&iacute; dụ 2: Dịch văn bản chữ H&aacute;n</p>
<p><span style="text-decoration: underline;"><strong>Một số lưu &yacute;:</strong></span></p>
<ol>
<li>Để nhập liệu văn bản H&aacute;n N&ocirc;m c&oacute; thể sử dụng bộ g&otilde; Thương Hiệt được t&iacute;ch hợp trong c&ocirc;ng cụ.</li>
<li>Để biết chi tiết về c&aacute;ch sử dụng bộ g&otilde;, người d&ugrave;ng c&oacute; thể v&agrave;o thanh menu của trang web để xem hướng dẫn sử dụng bộ g&otilde; Thương Hiệt.</li>
<li>Tổng số k&yacute; tự tối đa c&oacute; thể nhập l&agrave; 500 k&yacute; tự.<br /><br /></li>
</ol>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image06.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 6</strong>. Bộ g&otilde; Thương Hiệt</p>
<p>&nbsp;</p>
<h3 id="tag2">2. Nhận dạng v&agrave; dịch văn bản H&aacute;n N&ocirc;m dạng h&igrave;nh ảnh</h3>
<p><strong>- Bước 1:</strong> V&agrave;o website https://tools.clc.hcmus.edu.vn/ (tương tự bước 1 của phần Dịch văn bản)</p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image07.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 7</strong>. Lựa chọn t&iacute;nh năng nhận dạng v&agrave; dịch văn bản từ h&igrave;nh ảnh</p>
<p><strong>- Bước 2:</strong> Chọn mục h&igrave;nh ảnh</p>
<p><strong>- Bước 3:</strong> Lựa chọn 1 trong 3 t&iacute;nh năng dịch h&igrave;nh ảnh ph&ugrave; hợp với loại ảnh muốn dịch Hiện tại website hỗ trợ 3 loại h&igrave;nh ảnh ch&iacute;nh</p>
<ol>
<li>&nbsp;H&igrave;nh ảnh văn bản th&ocirc;ng thường (c&aacute;c văn bản H&aacute;n N&ocirc;m trong s&aacute;ch ở c&aacute;c thể loại như văn học, lịch sử,&hellip;)</li>
<li>H&igrave;nh ảnh văn bản h&agrave;nh ch&iacute;nh (Sắc Phong, Chế Phong, Chiếu Phong,&hellip;)</li>
<li>H&igrave;nh ảnh ngoại cảnh H&aacute;n tự (chữ H&aacute;n tr&ecirc;n đền ch&ugrave;a, ho&agrave;nh phi, cuốn thư,&hellip;)</li>
</ol>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image08.png" /></p>
<p style="text-align: center;"><strong>H&igrave;nh 8</strong>. T&iacute;nh năng nhận diện 3 loại h&igrave;nh ảnh văn bản H&aacute;n N&ocirc;m kh&aacute;c nhau</p>
<p><strong>- Bước 3</strong>: <strong>&lt;Đối với sử dụng c&ocirc;ng cụ CLC tr&ecirc;n điện thoại&gt;</strong> Về việc tải h&igrave;nh ảnh l&ecirc;n để c&ocirc;ng cụ nhận dạng, người sử dụng n&ecirc;n chụp trước bằng ứng dụng chụp ảnh (camera) của điện thoại rồi mới tải l&ecirc;n <strong>(Lựa chọn thư viện ảnh - &ldquo;Photo Library&rdquo; như ảnh minh hoạ b&ecirc;n dưới).</strong></p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image09.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 9</strong>. Chức năng upload ảnh tr&ecirc;n điện thoại</p>
<p><strong>- Bước 4: Nhận kết quả trả về</strong></p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image10.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 10</strong>. Kết quả dịch h&igrave;nh ảnh văn bản H&aacute;n N&ocirc;m dạng s&aacute;ch</p>
<p><em><strong>Lưu &yacute;:</strong></em> Với loại ảnh của c&aacute;c văn bản h&agrave;nh ch&iacute;nh, t&iacute;nh năng dịch nghĩa sẽ tự động chuyển qua m&ocirc; h&igrave;nh dịch nghĩa văn bản h&agrave;nh ch&iacute;nh (kh&aacute;c với m&ocirc; h&igrave;nh dịch nghĩa của t&iacute;nh năng dịch văn bản bằng c&aacute;ch nhập trực tiếp v&agrave;o)</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image11.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 11</strong>. Kết quả dịch h&igrave;nh ảnh văn bản h&agrave;nh ch&iacute;nh H&aacute;n N&ocirc;m</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image12.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 12</strong>. Kết quả dịch h&igrave;nh ảnh c&oacute; văn bản chữ H&aacute;n ngoại cảnh</p>
<p>&nbsp;</p>
<h3 id="tag3">3. Một số t&iacute;nh năng kh&aacute;c</h3>
<h4 id="tag31"><em>3.1 Xem lại lịch sử dịch</em></h4>
<p><strong>- Bước 1:</strong> Chọn v&agrave;o chức năng &ldquo;Lịch sử&rdquo;</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image13.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 13</strong>. Lựa chọn chức năng xem lại lịch sử c&aacute;c văn bản đ&atilde; dịch</p>
<p><strong>- Bước 2:</strong> Xem danh s&aacute;ch lịch sử c&aacute;c văn bản đ&atilde; dịch</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image14.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 14</strong>. Lịch sử c&aacute;c văn bản đ&atilde; dịch</p>
<h4 id="tag32"><em>3.2 Chức năng lưu văn bản đ&atilde; dịch</em></h4>
<p><strong>- Bước 1:</strong> Chọn biểu tượng dấu sao b&ecirc;n cạnh văn bản đ&iacute;ch, khi dấu sao trở th&agrave;nh m&agrave;u v&agrave;ng l&agrave; hệ thống đ&atilde; lưu th&agrave;nh c&ocirc;ng.</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image15.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 15.</strong> Lưu văn bản đ&atilde; dịch</p>
<p>- Bước 2: Xem lại c&aacute;c văn bản đ&atilde; lưu</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image16.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 16.</strong> Chọn v&agrave;o chức năng &ldquo;Đ&Atilde; LƯU&rdquo;</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image17.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 17.</strong> Hệ thống hiển thị c&aacute;c văn bản đ&atilde; lưu</p>
<h4 id="tag33"><em>3.3 Chức năng chuyển đổi ng&ocirc;n ngữ của giao diện</em></h4>
<p>Lựa chọn ng&ocirc;n ngữ hiển thị l&agrave; tiếng Việt (Vietnamese) hay tiếng Anh (English)</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image18.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 18.</strong> Lựa chọn ng&ocirc;n ngữ hiển thị cho website l&agrave; tiếng Anh</p>
<h4 id="tag34"><em>3.4 Chức năng sao ch&eacute;p bản dịch</em></h4>
<p>Bấm v&agrave;o biểu tượng sao ch&eacute;p ở khung của ng&ocirc;n ngữ đ&iacute;ch để sao ch&eacute;p bản dịch</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image19.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 19</strong>. Sao ch&eacute;p bản dịch</p>
<h4 id="tag35"><em>3.5 Chức năng tải bản dịch</em></h4>
<p><strong>- Bước 1:</strong> Chọn v&agrave;o biểu tượng tải xuống ở khung của văn bản đ&iacute;ch.</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image20.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 20.</strong> Chọn chức năng tải xuống bản dịch</p>
<p><strong>- Bước 2:</strong> Lựa chọn định dạng tập tin tải về</p>
<p><strong><img style="display: block; margin-left: auto; margin-right: auto;" src="/instruction-2/image21.png" /></strong></p>
<p style="text-align: center;"><strong>H&igrave;nh 21.</strong> Chọn văn bản về dạng tập tin .txt</p>
<p>&nbsp;</p>`

export default {
  name: "Instruction2",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      selectedLanguage: "vi",
      languageOptions: [
        { text: "Tiếng Việt", value: "vi" },
        { text: "English", value: "en" },
      ],
      numPages: 0,
      html_body : ''
    };
  },
  computed: {
    
  },
  methods: {
    handleChangeLanguage() {
      // Xử lý khi ngôn ngữ được thay đổi
      this.loadHTML();
    },
    async loadHTML() {
      if(this.selectedLanguage === 'vi'){
        this.html_body = htmlVi
      } 
      else
      {
        this.html_body = htmlVi
      }
      
    },
  },
  mounted() {
    this.loadHTML();
  },
};
</script>

